// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  tanantId: 'tenantA',

  maxSheetCount: 5,

  // Dev1
  // firebase: {
  //   apiKey: 'AIzaSyA3FKvqKfwiBEunymVk9halLin3_sl9HnY',
  //   authDomain: 'simplemapbuilderdev.firebaseapp.com',
  //   databaseURL: 'https://simplemapbuilderdev.firebaseio.com',
  //   projectId: 'simplemapbuilderdev',
  //   storageBucket: 'simplemapbuilderdev.appspot.com',
  //   messagingSenderId: '1021386503270',
  //   appId: '1:1021386503270:web:f5ea507252fd26eb'
  // },
  // gapi: {
  //   clientId: '1021386503270-8a4lrbtjmgvd87ge4aaumsfrkg5ddapn.apps.googleusercontent.com',
  //   apiKey: 'AIzaSyA3FKvqKfwiBEunymVk9halLin3_sl9HnY',
  //   discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
  //   scopes: [
  //     'https://www.googleapis.com/auth/drive.metadata.readonly',
  //     'https://www.googleapis.com/auth/spreadsheets.readonly'
  //   ]
  // },

  // // Dev2
  // firebase: {
  //   apiKey: "AIzaSyAsyS9uNrDZs3wOvJAISEF9I00iH6y2VSc",
  //   authDomain: "simplemapbuilderdev2.firebaseapp.com",
  //   databaseURL: "https://simplemapbuilderdev2.firebaseio.com",
  //   projectId: "simplemapbuilderdev2",
  //   storageBucket: "simplemapbuilderdev2.appspot.com",
  //   messagingSenderId: "63193710095",
  //   appId: "1:63193710095:web:4b2f939874c2bcc6016d7a"
  // },
  // gapi: {
  //   clientId: '63193710095-m8kapuk6r4p2tnh871j9psavel7db8tk.apps.googleusercontent.com',
  //   apiKey: 'AIzaSyAsyS9uNrDZs3wOvJAISEF9I00iH6y2VSc',
  //   discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
  //   scopes: [
  //     'https://www.googleapis.com/auth/drive.metadata.readonly',
  //     'https://www.googleapis.com/auth/spreadsheets.readonly'
  //   ]
  // },

  // Dev3
  firebase: {
    apiKey: "AIzaSyCNkVkvYyFKRqedNo1LFaKqFT04QjNox24",
    authDomain: "dev.s.dsmap.jp",
    databaseURL: "https://smap-spreadsheet.firebaseio.com",
    projectId: "smap-spreadsheet",
    storageBucket: "smap-spreadsheet.appspot.com",
    messagingSenderId: "702401183832",
    appId: "1:702401183832:web:9eead9e1a1f028390f4a2d",
    measurementId: "G-PR8K604ZHX"
  },
  gapi: {
    clientId: '702401183832-h1c3jjgc0k01b4ca7mo8pee59gg2i7oi.apps.googleusercontent.com',
    apiKey: 'AIzaSyCNkVkvYyFKRqedNo1LFaKqFT04QjNox24',
    discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
    scopes: [
      'https://www.googleapis.com/auth/drive.metadata.readonly',
      'https://www.googleapis.com/auth/spreadsheets.readonly'
    ]
  },

  // // Dev4
  // firebase: {
  //   apiKey: "AIzaSyAHw1qXWEt--kKXHHGcRCTB3E2mGAFDELg",
  //   authDomain: "simplemapbuilderdev4.firebaseapp.com",
  //   databaseURL: "https://simplemapbuilderdev4.firebaseio.com",
  //   projectId: "simplemapbuilderdev4",
  //   storageBucket: "simplemapbuilderdev4.appspot.com",
  //   messagingSenderId: "1002510522902",
  //   appId: "1:1002510522902:web:8d9a05ebf3887ee2b7f337"
  // },
  // gapi: {
  //   clientId: '1002510522902-mpdr7bti1i1ps270feg8iub4d5q6ho5b.apps.googleusercontent.com',
  //   apiKey: 'AIzaSyAHw1qXWEt--kKXHHGcRCTB3E2mGAFDELg',
  //   discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
  //   scopes: [
  //     'https://www.googleapis.com/auth/drive.metadata.readonly',
  //     'https://www.googleapis.com/auth/spreadsheets.readonly'
  //   ]
  // },

  // // Dev5
  // firebase: {
  //   apiKey: "AIzaSyC5rg1Pj4Gly251kSBcor0PTgQaRiIbBds",
  //   authDomain: "simplemapbuilderdev5.firebaseapp.com",
  //   databaseURL: "https://simplemapbuilderdev5.firebaseio.com",
  //   projectId: "simplemapbuilderdev5",
  //   storageBucket: "simplemapbuilderdev5.appspot.com",
  //   messagingSenderId: "1078494173472",
  //   appId: "1:1078494173472:web:7d6ccc0ae1bc59ed9adf45"
  // },
  // gapi: {
  //   clientId: '1078494173472-tnk7nlr4sgtumuptt139vsthkt9hj70i.apps.googleusercontent.com',
  //   apiKey: 'AIzaSyC5rg1Pj4Gly251kSBcor0PTgQaRiIbBds',
  //   discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
  //   scopes: [
  //     'https://www.googleapis.com/auth/drive.metadata.readonly',
  //     'https://www.googleapis.com/auth/spreadsheets.readonly'
  //   ]
  // },

  // // Dev6
  // firebase: {
  //   apiKey: "AIzaSyDYyj6cKGmdzV9r9oOzWVF8TeFKozTK9Q4",
  //   authDomain: "simplemapbuilderdev6.firebaseapp.com",
  //   databaseURL: "https://simplemapbuilderdev6.firebaseio.com",
  //   projectId: "simplemapbuilderdev6",
  //   storageBucket: "simplemapbuilderdev6.appspot.com",
  //   messagingSenderId: "885637116082",
  //   appId: "1:885637116082:web:b7afee5a169014dbefd9ab"
  // },
  // gapi: {
  //   clientId: '885637116082-hub7hdbgmfnego3oal15orgn3rq6tbtt.apps.googleusercontent.com',
  //   apiKey: 'AIzaSyDYyj6cKGmdzV9r9oOzWVF8TeFKozTK9Q4',
  //   discoveryDocs: ["https://sheets.googleapis.com/$discovery/rest?version=v4"],
  //   scopes: [
  //     'https://www.googleapis.com/auth/drive.metadata.readonly',
  //     'https://www.googleapis.com/auth/spreadsheets.readonly'
  //   ]
  // },

  // public_user: {
  //   email: 'smapuser@gmail.com',
  //   pw: 'fD46wDZX2nTPQwQUWd@#97YQ',
  // }
  defaultCoordinate: {
    latitude: 34.724451,
    longitude: 137.437378,
  },
  coordinateRange: {
    ne: {
      latitude: 45.3326,
      longitude: 153.5912,
    },
    sw: {
      latitude: 20.2531,
      longitude: 122.5557,
    },
  },
  splashSize: [
    {
      width: 414,
      height: 896,
      devicePixelRatio: 3
    },
    {
      width: 375,
      height: 812,
      devicePixelRatio: 3
    },
    {
      width: 414,
      height: 896,
      devicePixelRatio: 2
    },
    {
      width: 414,
      height: 736,
      devicePixelRatio: 3
    },
    {
      width: 375,
      height: 667,
      devicePixelRatio: 2
    },
    {
      width: 320,
      height: 568,
      devicePixelRatio: 2
    },
    {
      width: 1024,
      height: 1366,
      devicePixelRatio: 2
    },
    {
      width: 834,
      height: 1194,
      devicePixelRatio: 2
    },
    {
      width: 834,
      height: 1112,
      devicePixelRatio: 2
    },
    {
      width: 768,
      height: 1024,
      devicePixelRatio: 2
    },    
  ],
  iconSize: {
    width: 512,
    dataMB: 5
  },

  domain: {
    manage: 'dev.s.dsmap.jp',
    user: 'dev.s.dsapp.site',
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
