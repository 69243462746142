import { Injectable } from '@angular/core';
// import { MDBModalService } from 'ng-uikit-pro-standard';
import { map, first } from 'rxjs/operators';
import { AppThemeDef, MapSourceType } from 'src/app/usecase/sheet-usecase.service';
import { MapTypeModalComponent } from './map-type-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { MapTypeModalComponent } from './map-type-modal.component';

@Injectable({ providedIn: 'root' })
export class MapTypeModalService {

  constructor(private modalService: NgbModal) {
  }

  async show(
    theme: AppThemeDef,
    title: string,
    value: MapSourceType,
  ): Promise<{ result: boolean, value?: MapSourceType }> {

    console.debug(`${this.constructor.name}:show`);

    const modalRef = this.modalService.open(MapTypeModalComponent); // { ariaLabelledBy: 'modal-basic-title', size: 'xl'}
    const conpoment = modalRef.componentInstance as MapTypeModalComponent;
    conpoment.theme = theme;
    conpoment.title = title;
    conpoment.value = value;

    return modalRef.result;
  }

}
