<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title w-100" id="myModalLabel">アプリ公開</h4>

    <button type="button" class="close" aria-label="Close" (click)="onCloseClick()">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>
  <div class="modal-body container-fluid">
    <div class="row align-items-center">

      <div class="col-12 text-center">
          <!--<div>
            <h3 class="mb-0" style="font-weight: bold;">一般ユーザー</h3>
          </div>-->
          <div>
            <ngx-qrcode
              [elementType]="elementType"
              [value] = "userUrl"
              cssClass = "aclass"
              errorCorrectionLevel = "L">
            </ngx-qrcode>
          </div>
          <div class="form-group">
            <textarea readonly class="form-control" rows="3">{{userUrl}}</textarea>
          </div>
          <button type="button" ngbAutofocus class="col-8 btn btn-secondary" aria-label="Close" (click)="onCopy(true)"><i class="fas fa-copy"></i>&nbsp;クリップボードへコピー</button>
          <div class="mt-2" style="font-size: 16px;">QRコードもしくはURLをユーザーへ共有してください</div>
      </div>

    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="col-2 btn btn-primary" aria-label="Close" (click)="onCloseClick()">閉じる</button>
  </div>
</div>
