<div class="d-flex flex-column" style="height: 100%;"
  [style.margin-left.px]="showSideBar ? '250' : '0'"
  [style.margin-right.px]="showSideBar ? '400' : '0'"
  [style.width]="showSideBar ? 'calc(100% - 650px)' : '100%'"
>
  <!-- <div style="height:20px; background-color: red;"></div> -->
  <div class="bottom-info-panel flex-fill d-flex flex-row px-2" (click)="onInfoWindowModalShow()" style="position: relative; align-items: center; max-width: 100%">

    <div class="flex-fill d-flex flex-column" [style.padding-right]="bottomImage ? '15px' : ''" [style.max-width]="bottomImage ? 'calc(100% - 75px)' : '100%'">
      <div style="font-size: 20px; max-width: 100%; height: 30px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
        {{bottomInfoTitle}}
      </div>
      <div style="font-size: 14px; max-width: 100%; height: 21px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
        {{bottomInfoSub1}}
      </div>
      <div style="font-size: 14px; max-width: 100%; height: 21px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
        {{bottomInfoSub2}}
      </div>
    </div>
    <div *ngIf="bottomImage" style="height: 60px; width: 60px; margin-left: 15px; display: flex; align-items: center; outline-style: solid; outline-width: 1px;">
      <img [src]="bottomImage" style="width: 100%; height: auto; object-fit: contain; max-height: 100%;"/>
    </div>

  </div>

</div>
