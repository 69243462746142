import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppModel, RefreshFlags } from 'src/app/usecase/app-usecase.service';
import { SheetEntity } from 'src/app/usecase/sheet-usecase.service';
import { LatLngTuple } from 'leaflet';
import { AppSettingModalService } from '../../modal/app-setting-modal/app-setting-modal.service';
import { UserEntity, AuthUsecase } from 'src/app/usecase/auth-usecase.service';
import { MapTypeModalService } from '../../modal/map-type-modal/map-type-modal.service';
import { ShareModalService } from '../../modal/share-modal/share-modal.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-app-setting-panel',
  templateUrl: './app-setting-panel.component.html',
  styleUrls: ['./app-setting-panel.component.scss']
})
export class AppSettingPanelComponent implements OnInit {

  @Input()
  app: AppModel;

  @Input()
  sheet: SheetEntity

  @Input()
  selectedSheetId = '';
  @Output()
  readonly selectedSheetIdChange = new EventEmitter<string>();

  @Input()
  selectedSheetEnabled = false;
  @Output()
  readonly selectedSheetEnabledChange = new EventEmitter<boolean>();

  @Input()
  currentCamera: { center: LatLngTuple, zoom: number };
  @Output()
  readonly requestMapPosition = new EventEmitter<({ center: LatLngTuple, zoom: number })=>void>();

  @Output()
  readonly backClick = new EventEmitter<void>();

  @Output()
  readonly reloadClick = new EventEmitter<void>();

  @Input()
  readonly deleteAppPromise: ()=>Promise<void>;

  @Output()
  readonly onDirtyChange = new EventEmitter<boolean|RefreshFlags>();

  @Output()
  readonly clearRemainingError = new EventEmitter<void>();

  @Input()
  reservedSaveCount: {reservedSaveTime: number};

  @Output() readonly initGps = new EventEmitter<void>();
  @Output() readonly moveToAllMarker = new EventEmitter<void>();
  @Output() readonly imageUploadStart = new EventEmitter<void>();
  @Output() readonly imageUploadFinish = new EventEmitter<void>();

  get sheets(): SheetEntity[] {
    if (this.app != null && this.app.sheets != null) {
      return this.app.sheets;
    } else {
      return [];
    }
  }

  get user(): UserEntity {
    return this.auth.user;
  }

  get appImage(): string {
    const shareId = this?.app?.shareId ?? 'default';
    return `https://storage.googleapis.com/${environment.firebase.storageBucket}/${shareId}/appicon.png?v=${this.time}`;
  }

  packs = ['mat'];
  fallbackIcon = 'account_circle';

  sheetName: string = '';
  sheetNameReadyToEdit: boolean = false;

  time = new Date().getTime();

  constructor(
    private appSettingModal: AppSettingModalService,
    private shareModal: ShareModalService,
    private mapTypeModal: MapTypeModalService,
    private auth: AuthUsecase,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.sheet.name !== this.sheetName) this.sheetName = this.sheet.name;
  }

  back() {
    this.backClick.emit();
  }

  onDirty(refreshMap: boolean|RefreshFlags) {
    if (refreshMap!=null && typeof refreshMap!='boolean' && refreshMap.changeShareId) refreshMap.callback = ()=>{this.time = new Date().getTime();};
    this.onDirtyChange.emit(refreshMap);
  }

  onSelectedSheetIdChange(event: string) {
    this.selectedSheetId = event;
    this.selectedSheetIdChange.emit(this.selectedSheetId);
  }

  onSelectedSheetEnabledChange(event: boolean) {
    this.selectedSheetEnabled = event;
    this.selectedSheetEnabledChange.emit(this.selectedSheetEnabled);
  }

  // onIconPickerSelect(icon: string): void {
  //   console.debug(`${this.constructor.name}::onIconPickerSelect`, icon);
  //   // this.iconCss.setValue(icon);
  //   this.sheet.icon = icon;
  //   this.onDirty(false);
  // }

  onRequestMapPosition(requester: (InitialPositionLatLngDef)=>void) {
    const f = (value:{ center: LatLngTuple, zoom: number }) => {
      const latLonZoom = Object.assign({}, value);
      requester(latLonZoom);
    };
    //const latLonZoom = Object.assign({}, this.currentCamera);
    //latLonZoom.center[0] = Math.floor(latLonZoom.center[0] * 100) / 100;
    //latLonZoom.center[1] = Math.floor(latLonZoom.center[1] * 100) / 100;
    //requester(latLonZoom);
    this.requestMapPosition.emit(f);
  }

  onGotoGoogleDoc() {
    window.open(`https://docs.google.com/spreadsheets/d/${this.app.sheetDocId}`, "_blank");
  }

  onReload() {
    this.reloadClick.emit();
  }

  onSheetNameDecided(event) {
    if ((event.type !== 'keyup' || event.keyCode == 13) && this.sheet.name !== this.sheetName) {
      this.sheet.name = this.sheetName;
      this.onDirty(false);
      this.sheetNameReadyToEdit = (event.type !== 'blur');
    }
  }

  onSheetNameFocus() {
    this.sheetNameReadyToEdit = true;
  }

  onSheetNameEdit() {
    if (this.sheetNameReadyToEdit && this.sheet.name !== this.sheetName) {
      this.sheetNameReadyToEdit = false;
      if (typeof this.reservedSaveCount.reservedSaveTime!='undefined') this.reservedSaveCount.reservedSaveTime++;
    } else if (!this.sheetNameReadyToEdit && this.sheet.name === this.sheetName) {
      this.sheetNameReadyToEdit = true;
      if (typeof this.reservedSaveCount.reservedSaveTime!='undefined') this.reservedSaveCount.reservedSaveTime--;
    }
  }

  async onShowAppSettingModal() {
    this.clearRemainingError.emit();

    const res = await this.appSettingModal.show(this.app, this.sheet, (refreshMap)=>this.onDirty(refreshMap), ()=>{
      const latLonZoom = Object.assign({}, this.currentCamera);
      return latLonZoom;
    }, this.reservedSaveCount, ()=>this.onInitGps(), ()=>this.onMoveToAllMarker(), ()=>this.onImageUploadStart(), ()=>this.onImageUploadFinish());
    console.log(`${this.constructor.name}: AppSettingPanelComponent -> onShowAppSettingModal -> res`, res);
    this.time = new Date().getTime();

    // アプリケーション削除
    if (res != null) {
      await this.deleteAppPromise();
    }
  }

  onInitGps() {
    this.initGps.emit();
  }

  onMoveToAllMarker() {
    this.moveToAllMarker.emit();
  }

  onImageUploadStart() {
    this.imageUploadStart.emit();
  }

  onImageUploadFinish() {
    this.imageUploadFinish.emit();
  }

  /*async onShowAppShareModal() {
    await this.shareModal.show(this.app);
  }*/

}
