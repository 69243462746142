import { Injectable } from '@angular/core';
// import { MDBModalService } from 'ng-uikit-pro-standard';
import { first } from 'rxjs/operators';
import { InfoWindowModalComponent } from './info-window-modal.component';
import { AppThemeDef } from 'src/app/usecase/sheet-usecase.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class InfoWindowModalService {

  modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {
  }

  show(
    theme: AppThemeDef,
    title: string,
    sub1: { title: string, value: string },
    sub2: { title: string, value: string },
    items: { title: string, value: string, valueType?: string }[],
    gotoMapApp: ()=>void,
    gotoPhoneApp: (string)=>void,
    gotoOtherPage: (string)=>void): Promise<void> {

    console.debug(`${this.constructor.name}:show`);

    const modalRef = this.modalService.open(InfoWindowModalComponent, {centered: true}); // { ariaLabelledBy: 'modal-basic-title', size: 'xl'}
    const component = modalRef.componentInstance as InfoWindowModalComponent;
    component.theme = theme;
    component.title = title;
    component.sub1 = sub1;
    component.sub2 = sub2;
    component.items = items;
    component.gotoMapApp = gotoMapApp;
    component.gotoPhoneApp = gotoPhoneApp;
    component.gotoOtherPage = gotoOtherPage;

    this.modalRef = modalRef;

    return modalRef.result.then(x => null);
  }

  close() {
    this.modalRef?.dismiss();
  }

}
