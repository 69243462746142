<div>
  <ng-container *ngFor="let item of value.items; index as i">
    <div class="row">
      <label class="field-label col-12">
        {{item.column}}
      </label>
      <div class="col-12 mb-2">
        <ng-container *ngIf="item.valueType == 'text'">
          <input type="text" class="form-control" [ngModel]="getValue(i)" (ngModelChange)="setValue(i, $event)">
        </ng-container>

        <ng-container *ngIf="item.valueType == 'date'">
          <div class="d-flex align-items-center">
            <input type="date" id="input" class="form-control" [ngModel]="getArrayValue(i, 0)" (ngModelChange)="setArrayValue(i, 0, $event)">
            <span class="mx-2">～</span>
            <input type="date" id="input" class="form-control" [ngModel]="getArrayValue(i, 1)" (ngModelChange)="setArrayValue(i, 1, $event)">
          </div>
        </ng-container>

        <ng-container *ngIf="item.valueType == 'list'">
          <app-mddb-select-object
            [outline]="true"
            [options]="listItemMap.get(item.column)"
            [placeholder]="item.column + 'を選択'"
            [ngModel]="getValue(i)" (ngModelChange)="setValue(i, $event)">
          </app-mddb-select-object>

        </ng-container>

      </div>
    </div>
  </ng-container>
</div>
