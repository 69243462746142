import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { InfoWindowItemDef } from 'src/app/usecase/sheet-usecase.service';

@Component({
  selector: 'app-info-window-column-select',
  templateUrl: './info-window-column-select.component.html',
  styleUrls: ['./info-window-column-select.component.scss']
})
export class InfoWindowColumnSelectComponent implements OnInit {
  @Input() no = '';
  @Input() placeholder = '';
  @Input() visibleDeleteButton = true;

  @Output() readonly delete = new EventEmitter<void>();

  @Input('columns')
  set columnsSetter(value: string[]) {
    this.options = value.map(x => {
      return { value: x, label: x };
    });

    const org = this.value;
    this.value = {} as InfoWindowItemDef;
    this.ref.detectChanges();
    this.value = org;
    this.ref.detectChanges();
  }
  @Input() value: InfoWindowItemDef;
  @Output() readonly valueChange = new EventEmitter<InfoWindowItemDef>();

  options: { value: string, label: string }[] = [];

  valueTypes: { value: string, label: string }[] = [
    { value: 'text', label: 'テキスト' },
    { value: 'phone', label: '電話番号' },
    { value: 'url', label: 'URL' },
    { value: 'image', label: '画像' },
  ];

  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit() {
  }

  onColumnChange(event) {
    const newValue = this.value; // Object.assign({}, this.value);
    newValue.column = event;
    this.value = newValue;
    this.valueChange.emit(this.value);
  }

  onValueTypeChange(event) {
    const newValue = this.value; // Object.assign({}, this.value);
    newValue.valueType = event;
    this.value = newValue;
    this.valueChange.emit(this.value);
  }

}
