import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  template:
    `
<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('dissmiss')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{message}}</p>
</div>
<div class="modal-footer">
  <div class="col-12 text-center">
    <button type="button" class="col-3 mr-3 btn btn-primary" (click)="activeModal.close('ok')">{{okCaption}}</button>
    <button type="button" ngbAutofocus class="col-3 btn btn-outline-dark" (click)="activeModal.dismiss('cancel')">{{cancelCaption}}</button>
  </div>
</div>
  `
})
// tslint:disable-next-line
export class ConfirmModalContent {
  @Input() title: string;
  @Input() message: string;
  @Input() okCaption = '決定';
  @Input() cancelCaption = 'キャンセル';
  constructor(public activeModal: NgbActiveModal) { }
}
