export class SvgMarker {

  public static svg = `<?xml version="1.0" standalone="no"?>
  <svg width="300px" height="400px" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
          <path stroke="none" fill="gray" d="
            M  50 250
            L 250 250
            L 150 400
            L  50 250
            Z"/>

          <path stroke="none" fill="{color1}" d="
            M 0 150
            A 150 150 0 0 1 150 0
            L 150 100
            A 50 50 0 0 0 100 150
            L 0 150
            Z"/>

          <path stroke="none" fill="{color2}" d="
            M 150 0
            A 150 150 0 0 1 300 150
            L 200 150
            A 50 50 0 0 0 150 100
            L 150 0
            Z"/>

          <path stroke="none" fill="{color3}" d="
            M 300 150
            A 150 150 0 0 1 150 300
            L 150 200
            A 50 50 0 0 0 200 150
            L 300 150
            Z"/>

          <path stroke="none" fill="{color4}" d="
            M 150 300
            A 150 150 0 0 1 0 150
            L 100 150
            A 50 50 0 0 0 150 200
            L 150 300
            Z"/>
      </g>
  </svg>
  `;
}
