import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { AppSettingModalComponent } from './app-setting-modal.component';
import { AppModel } from 'src/app/usecase/app-usecase.service';
import { InitialPositionLatLngDef, SheetEntity } from 'src/app/usecase/sheet-usecase.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class AppSettingModalService {

  constructor(private modalService: NgbModal) {
  }

  async show(app: AppModel, sheet: SheetEntity, onDirty: (boolean)=>void,
    onMapPosition: ()=>InitialPositionLatLngDef, reservedSaveCount: {reservedSaveTime: number}, initGps: ()=>void, moveToAllMarker: ()=>void,
    imageUploadStart: ()=>void, imageUploadFinish: ()=>void): Promise<{ appDelete: boolean }> {
    console.debug(`${this.constructor.name}:show`, app);
    // const modalOptions = {
    //   backdrop: true,
    //   keyboard: true,
    //   focus: true,
    //   show: false,
    //   ignoreBackdropClick: false,
    //   class: 'modal-lg modal-dialog-centered',
    //   containerClass: '',
    //   animated: true,
    //   data: {
    //     app: app,
    //     sheet: sheet,
    //     onDirty: onDirty,
    //     onDeleteApp: onDeleteApp,
    //     onMapPosition: onMapPosition,
    //   }
    // };

    // this.modalService.show(AppSettingModalComponent, modalOptions);
    // return this.modalService.closed.pipe(first()).toPromise();


    console.debug(`${this.constructor.name}:show`);

    const modalRef = this.modalService.open(AppSettingModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', centered: true});
    const component = modalRef.componentInstance as AppSettingModalComponent;
    component.app = app;
    component.sheet = sheet;
    component.onDirty = onDirty;
    component.onMapPosition = onMapPosition;
    component.initGps = initGps;
    component.moveToAllMarker = moveToAllMarker;
    component.imageUploadStart = imageUploadStart;
    component.imageUploadFinish = imageUploadFinish;
    component.reservedSaveCount = reservedSaveCount;

    component.shareId = app.shareId;

    return modalRef.result.then(x => {
      return x;
    });

  }

  onRequestMapPosition(requester: (x:InitialPositionLatLngDef)=>void) {
  }
}
