import { Component, OnInit } from '@angular/core';
import { AppModel, RefreshFlags } from 'src/app/usecase/app-usecase.service';
import { InitialPositionLatLngDef, SheetEntity, SheetUsecase } from 'src/app/usecase/sheet-usecase.service';
import { environment } from 'src/environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from "@angular/platform-browser";
import { ConfirmModalService } from '../confirm-modal/confirm-modal.service';
import { ShareModalService } from '../../modal/share-modal/share-modal.service';

@Component({
  selector: 'app-app-setting-modal',
  templateUrl: './app-setting-modal.component.html',
  styleUrls: ['./app-setting-modal.component.scss']
})
export class AppSettingModalComponent {
  app: AppModel;
  sheet: SheetEntity;
  onDirty: (boolean)=>void;
  onMapPosition: ()=>InitialPositionLatLngDef;
  initGps: ()=>void;
  moveToAllMarker: ()=>void;
  imageUploadStart: ()=>void;
  imageUploadFinish: ()=>void;

  shareId: string;
  shareIdPublishEnable: boolean = true;
  shareIdErrorMessage: string;
  
  domain = '.' + environment.domain.user;

  reservedSaveCount: {reservedSaveTime: number};

  name: string;
  nameReadyToEdit: boolean = false;
  author: string;
  authorReadyToEdit: boolean = false;
  description: string;
  descriptionReadyToEdit: boolean = false;

  ngOnInit(): void {
    // this.myFormGroup = new FormGroup({iconCss: this.iconCss});
    this.name = this.app.name;
    this.author = this.app.author;
    this.description = this.app.description;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private titleService: Title,
    private confirmModal: ConfirmModalService,
    private shareModal: ShareModalService,
    private sheetUsecase: SheetUsecase,
  ) {
  }

  onCloseClick() {
    this.activeModal.close();
  }

  async onDeleteAppOuter() {
    if (await this.confirmModal.show('確認', '本当にこのアプリケーションを削除しますか？', 'はい', 'いいえ')) {
      this.activeModal.close({ appDelete: true });
      return;
    }
  }

  onRequestMapPosition(requester: (InitialPositionLatLngDef)=>void) {
    const pos = this.onMapPosition();
    requester(pos);
  }

  onImageChanged(data: string) {
    var imageRefreshFlags = {
      refreshMap: false,
      refreshManifest: false,
      changeShareId: false,
      oldShareId: this.app.shareId,
      imageSource: data,
      callback: null,
    } as RefreshFlags;
    this.onDirty(imageRefreshFlags);
  }

  onAppColorChanged() {
    var appColorRefreshFlags = {
      refreshMap: false,
      refreshManifest: true,
      changeShareId: false,
      oldShareId: this.app.shareId,
      imageSource: '',
      callback: null,
    }as RefreshFlags;
    this.onDirty(appColorRefreshFlags);
  }

  onTextDecided(event) {
    if (event.type !== 'keyup' || event.keyCode == 13) {
      if (this.app.name !== this.name) {
        this.app.name = this.name;
        var appNameRefreshFlags = {
          refreshMap: false,
          refreshManifest: true,
          changeShareId: false,
          oldShareId: this.app.shareId,
          imageSource: '',
          callback: null,
        }as RefreshFlags;
        this.onDirty(appNameRefreshFlags);
        this.nameReadyToEdit = (event.type !== 'blur');
        this.titleService.setTitle(this.name);
      } else if (this.app.author !== this.author) {
        this.app.author = this.author;
        this.onDirty(false);
        this.authorReadyToEdit = (event.type !== 'blur');
      } else if (this.app.description !== this.description) {
        this.app.description = this.description;
        this.onDirty(false);
        this.descriptionReadyToEdit = (event.type !== 'blur');
      }
    }
  }

  onTextFocus(text: 'name' | 'author' | 'description') {
    if (text == 'name') this.nameReadyToEdit = true;
    else if (text == 'author') this.authorReadyToEdit = true;
    else this.descriptionReadyToEdit = true;
  }

  onTextEdit(text: 'name' | 'author' | 'description') {
    if (text == 'name') {
      if (this.nameReadyToEdit && this.app.name !== this.name) {
        this.nameReadyToEdit = false;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime++;
      } else if (!this.nameReadyToEdit && this.app.name === this.name) {
        this.nameReadyToEdit = true;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime--;
      }
    } else if (text == 'author') {
      if (this.authorReadyToEdit && this.app.author !== this.author) {
        this.authorReadyToEdit = false;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime++;
      } else if (!this.authorReadyToEdit && this.app.author === this.author) {
        this.authorReadyToEdit = true;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime--;
      }
    } else {
      if (this.descriptionReadyToEdit && this.app.description !== this.description) {
        this.descriptionReadyToEdit = false;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime++;
      } else if (!this.descriptionReadyToEdit && this.app.description === this.description) {
        this.descriptionReadyToEdit = true;
        if (typeof this.reservedSaveCount.reservedSaveTime != 'undefined') this.reservedSaveCount.reservedSaveTime--;
      }
    }
  }

  async onShareIdChanged() {
    if (this.shareId==this.app.shareId) {
      this.shareIdPublishEnable = true;
    } else if (this.shareId == '') {
      this.shareIdPublishEnable = false;
      this.shareIdErrorMessage = '公開URLを入力してください。';
    } else if (this.shareId.match(/[^a-z0-9\-]|--|^-/)!=null) {
      this.shareIdPublishEnable = false;
      this.shareIdErrorMessage = 'URLに使用できない文字が含まれます。';
    } else if (await this.sheetUsecase.checkShareIdName(this.shareId)) {
      this.shareIdPublishEnable = false;
      this.shareIdErrorMessage = 'このURLは使用済みです。';
    } else if (this.shareId==this.app.shareId) {
      this.shareIdPublishEnable = true;
    } else if (this.shareId == '') {
      this.shareIdPublishEnable = false;
      this.shareIdErrorMessage = '公開URLを入力してください。';
    } else if (this.shareId.match(/[^a-z0-9\-]|--|^-/)!=null) {
      this.shareIdPublishEnable = false;
      this.shareIdErrorMessage = 'URLに使用できない文字が含まれます。';
    } else {
      this.shareIdPublishEnable = true;
    }
  }

  async onShareIdPublished(event) {
    if (event.type !== 'keyup' || event.keyCode == 13) {
      await this.onShareIdChanged();
      if (this.shareIdPublishEnable) {
        if (this.shareId!=this.app.shareId) {
          var appNameRefreshFlags = {
            refreshMap: false,
            refreshManifest: true,
            changeShareId: true,
            oldShareId: this.app.shareId,
            imageSource: '',
            callback: null,
          }as RefreshFlags;
          this.app.shareId = this.shareId;
          this.onDirty(appNameRefreshFlags);
        }
      }
    }
  }

  async onShareModalShow() {
    await this.onShareIdChanged();
    if (this.shareIdPublishEnable) this.shareModal.show(this.shareId);
  }
}
