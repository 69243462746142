import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalContent } from './confirm-modal-content';

@Injectable({
  providedIn: 'root'
})
export class ConfirmModalService {

  constructor(private modalService: NgbModal) { }

  show(title: string, message: string, okCaption?: string, cancelCaption?: string): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmModalContent, {centered: true});
    const component = modalRef.componentInstance as ConfirmModalContent;
    if (component != null) {
      component.title = title;
      component.message = message;
      component.okCaption = okCaption || '決定';
      component.cancelCaption = cancelCaption || 'キャンセル';
    }

    return modalRef.result.then(result => {
      return true;
    }, reason => {
      return false;
    });
  }
}
