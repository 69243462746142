import { Injectable } from '@angular/core';

import { GApiService, SheetMetaData } from 'src/app/service/gapi.service';
import { Moment } from 'moment';
import moment from 'moment';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import * as randomColor from 'randomcolor';
import { AppModel } from './app-usecase.service';
import { LatLng, LatLngTuple } from 'leaflet';
import * as Enumerable from 'linq';
import { AngularFireStorage } from '@angular/fire/storage';
import { BlobUtils } from '../ui/app-component/image-drop-panel/image-drop-panel.component';
import { StringUtils } from '../util/string-utils';

export type PositionDef = {
  latColumn: string,
  lonColumn: string,
}

export type MarkerItemDef = {
  column: string,
  color: string,
  trueValue: string,
};

export type MarkerDef = {
  markerType: "normal" | "thematic",

  // normal用
  defaultIconUrlColumn: string,

  // Thematic 用
  emptyColor: string,
  items: MarkerItemDef[],
};

export type InfoWindowItemDef = {
  column: string,
  valueType: 'text' | 'phone' | 'url' | 'image',
};

export type InfoWindowDef = {
  titleColumn: string,
  sub1Column: string,
  sub2Column: string,
  bottomPanelImage: string,
  items: InfoWindowItemDef[],
};

export type FilterValueType = 'text' | 'date' | 'list';

export type Filter = {
  column: string,
  valueType: FilterValueType,
  criteria: string | string[],
}

export type SearchItemDef = {
  column: string,
  valueType: FilterValueType,
};

export type SearchDef = {
  items: SearchItemDef[],
};

export type AppDef = {
  position: PositionDef,
  marker: MarkerDef,
  infoWindow: InfoWindowDef,
  search: SearchDef,
}; // Obsolete

export type SheetDef = AppDef; // New

export type InitialPotisionType = "boundingBox" | "latLonZoom" | "gps";

export type InitialPositionLatLngDef = { center: LatLngTuple, zoom: number }

export type InitialPositionDef = {
  type: InitialPotisionType,
  latLonZoom: InitialPositionLatLngDef,
  gpsOption: { accuracy?: "walk" | "bicycle" | "car" },
  zoom: number
}

export type AppThemeDef = {
  name: string,

  backgroundColor: string,
  foregroundColor: string,

  headerBackgroundColor: string, // ヘッダー背景色
  headerForegroundColor: string, // ヘッダー前景色

  footerBackgroundColor: string, // フッター背景色
  footerForegroundColor: string, // フッター前景色

  splashBackgroundColor: string, // 読込中背景色
  splashForegroundColor: string, // 読込中前景色
  buttonPrimaryBackgroundColor: string, // 主ボタン背景色
  buttonPrimaryForegroundColor: string, // 主ボタン前景色
  buttonSecondaryBackgroundColor: string, // 副ボタン背景色
  buttonSecondaryForegroundColor: string, // 副ボタン前景色
}

export type MapSourceType = "chiriin" | "osm";

export type ThemeType = "light_default" | "dark_default";

export type AppEntity = {
  id: string,
  shareId: string,
  icon: string,
  sheetDocId: string,
  name: string,
  description: string,
  author: string,
  lastSyncTime: string,
  lastAccessTime: string,
  // columns: string[], // move to SheetEntity
  color: string,
  // appDef: AppDef, // move to SheetEntity
  // initialPosition: InitialPositionDef, // move to SheetEntity
  // mapSourceType: MapSourceType, // move to SheetEntity
  themeType: ThemeType;
  theme: AppThemeDef;
  sheets: SheetEntity[]; // new
  owner: string,
};

export type SheetEntity = {
  id: string,
  icon: string,
  sheetId: string,
  sheetName: string,
  name: string,
  lastSyncTime: string,
  columns: string[],
  sheetDef: SheetDef,
  initialPosition: InitialPositionDef;
  mapSourceType: MapSourceType;
  enabled: boolean;
}

export class SpreadsheetModel {
  id: string;
  name: string;
  modifiedTime: string;
  owner: string;

  constructor(init?: Partial<SpreadsheetModel>) {
    if (init != null) {
      this.id = init.id;
      this.name = init.name;
      this.modifiedTime = init.modifiedTime;
    }
  }

  get modifiedTimeMoment(): Moment {
    return moment(this.modifiedTime);
  }

  get modifiedTimeFormated(): string {
    return this.modifiedTimeMoment.format('YYYY/MM/DD');
  }
};

@Injectable({ providedIn: 'root' })
export class SheetUsecase {

  private pageToken: string = null;

  constructor(
      private gapiService: GApiService,
      private afs: AngularFirestore,
      private storage: AngularFireStorage,
  ) {
  }

  async listDocuments(searchWord: string): Promise<SpreadsheetModel[]> {
    await this.gapiService.loadApi();
    const res = await this.gapiService.listDocuments(searchWord);
    return res.files.map(x => {
      let sheet = new SpreadsheetModel(x);
      sheet.owner=x.owners[0].displayName;
      return sheet;
    });
  }

  async createNewApp(userId: string, sheet: SpreadsheetModel/*, baseUrl: string*/): Promise<AppModel> {
    // Sets user data to firestore on login
    const tenantId = environment.tanantId;
    const appColl = this.afs.collection(`tenant/${tenantId}/user/${userId}/app`);
    const shareColl = this.afs.collection(`share`);
    const now = moment().format();

    const appColor = '#0d8563';/*randomColor({
      luminosity: 'dark',
      hue: 'random'
    });*/

    const findColumn = (columns: string[], words: string[], startIndex: number, defaultIndex: number) => {
      const index = columns.slice(startIndex).findIndex(col => words.find(word => col.indexOf(word) >= 0) != null);
      if (index >= 0) {
        return columns[index + startIndex];
      }
      return typeof columns[defaultIndex] !== 'undefined'? columns[defaultIndex] : columns[0];
    };

    const createSheetEntities = (sheet: SheetMetaData, enable: boolean): SheetEntity => {
      const columns = sheet.rows[0];
      const imageColumn = this.findSuggestedColumnWithoutDefault(columns,['画像','image']);
      const sheetDef = {
        position: {
          latColumn: this.findSuggestedColumn(columns, ['緯度', 'lat', 'y']),
          lonColumn: this.findSuggestedColumn(columns, ['経度', 'lon', 'x']),
        } as PositionDef,
        marker: {
          markerType: 'normal',
          emptyColor: '#DC143C',
          defaultIconUrlColumn: '',
          items: [
            {
              column: this.getColName(columns, 0),
              color: randomColor(),
              trueValue: '1',
            } as MarkerItemDef,
            {
              column: this.getColName(columns, 1),
              color: randomColor(),
              trueValue: '1',
            } as MarkerItemDef,
            {
              column: this.getColName(columns, 2),
              color: randomColor(),
              trueValue: '1',
            } as MarkerItemDef,
            {
              column: this.getColName(columns, 3),
              color: randomColor(),
              trueValue: '1',
            } as MarkerItemDef,
          ]
        } as MarkerDef,
        infoWindow: {
          titleColumn: findColumn(columns, ['名', 'title'], 0, 0),
          sub1Column: findColumn(columns, ['住所', 'address'], 1, 1),
          sub2Column: findColumn(columns, ['電話番号', '電話', 'tel'], 2, 2),
          bottomPanelImage: imageColumn,
          items: [
            { column: this.getColName(columns, 6), valueType: 'text' } as InfoWindowItemDef
          ],
        } as InfoWindowDef,
      } as SheetDef;

      return {
        id: sheet.id,
        icon: 'account_circle',
        sheetId: sheet.id,
        sheetName: sheet.title,
        name: sheet.title,
        lastSyncTime: now,
        columns: columns,
        initialPosition: {
          type: 'boundingBox',
          zoom: 15,
        } as InitialPositionDef,
        mapSourceType: 'chiriin',
        sheetDef: sheetDef,
        enabled: enable,
      } as SheetEntity
    };

    const spreadSheetData = await this.gapiService.getCellData(sheet.id);

    for (let sheet of Array.from(spreadSheetData.values())) {
      let errorMessage = 'Columns have problem';
      if (typeof sheet.rows === 'undefined') throw new Error(errorMessage);
      this.checkColumnsName(sheet.rows[0]);
      for (let index = 1; index < sheet.rows.length; index++) {
        if (sheet.rows[index].length > sheet.rows[0].length) throw new Error(errorMessage);
      }
    }

    const sheetEntities = Array.from(spreadSheetData.values()).map((sheet, index) => {
      return createSheetEntities(sheet, index < environment.maxSheetCount);
    });

    const cells = Array.from(spreadSheetData.values())[0].rows;
    // const columns = cells[0];
    const app = {
      sheetDocId: sheet.id,
      name: sheet.name,
      description: '',
      author: '',
      lastSyncTime: now,
      lastAccessTime: now,
      color: appColor,

      sheets: sheetEntities,
      owner: userId
    } as AppEntity;

    const appRef = await appColl.add(app);
    app.id = appRef.id;

    app.shareId = await this.createRandomShareId();
    //const shareRef = await shareColl.add({
    const shareRef = await shareColl.doc(app.shareId);
    shareRef.set({
      path: `tenant/${tenantId}/user/${userId}/app/${app.id}`
    });

    const doc = appColl.doc(appRef.id);
    //app.shareId = shareRef.id;
    await doc.set(app);

    let writeCount = 0;
    let batch = this.afs.firestore.batch();
    try {

      for (const sheet of Array.from(spreadSheetData.values())) {
        const sheetRowColl = this.afs.collection(`tenant/${tenantId}/user/${userId}/app/${app.id}/sheet/${sheet.id}/row`);

        const sheetEntity = app.sheets.find(x => x.sheetId === sheet.id);
        const columns = sheetEntity.columns;

        const rows = sheet.rows;
        for (let index = 1; index < rows.length; index++) {
          const rowId = `row${index.toString().padStart(5, '0')}`;
          const rowDoc = sheetRowColl.doc(rowId).ref;

          const rowData = rows[index].reduce((pre, cur, idx) => {
            pre[columns[idx]] = cur;
            return pre;
          }, {});

          batch.set(rowDoc, rowData);
          writeCount++;
          if (writeCount >= 500) {
            await batch.commit();
            writeCount = 0;
            batch = this.afs.firestore.batch();
          }
        }
      }

      await batch.commit();

      // 既定のアイコンをアップロード
      const file = await fetch('/assets/default_appicon.png')
        .then(response => response.blob())
        .then(blob => new File([blob], "appicon.png"));
      const ref = this.storage.ref(`${app.shareId}/appicon.png`);
      ref.put(file);

      // PWA manifest をアップロード
      const manifest = {
        name: app.name,
        short_name: app.name,
        //theme_color: '#1976d2',
        background_color: app.color,
        display: "standalone",
        scope: "./",
        start_url: `https://${app.shareId}.${environment.domain.user}/share_map_page`,//`${baseUrl}/share_map_page;share_id=${app.shareId}`,
        icons: [
          {
            src: "appicon.png",
            sizes: "128x128",
            type: "image/png",
            purpose: "maskable any"
          }
        ]
      };

      // アイコンからiOSのPWA用のスプラッシュスクリーンを生成してアップロード
      var canvas = document.createElement('canvas');
      if (typeof canvas.getContext == 'function') {
        var image = new Image();
        image.onload = async () => {
          var context = canvas.getContext('2d');
          for (let i = 0; i < environment.splashSize.length; i++) {
            let value = environment.splashSize[i];
            canvas.width = value.width * value.devicePixelRatio;
            canvas.height = value.height * value.devicePixelRatio;
            let imageSize = canvas.width * 0.3;
            let fontSize = canvas.width > 1000 ? 60 : 48;
            context.fillStyle = app.color;
            context.fillRect(0,0,canvas.width,canvas.height);
            context.fill();
            context.fillStyle = '#fff';
            context.textAlign = 'center';
            context.textBaseline = 'top';
            context.font = `bold ${fontSize}px "-apple-system, BlinkMacSystemFont"`;
            let appName = [''];
            let line = 0;
            for (let j = 0; j < app.name.length; j++) {
              let char = app.name.charAt(j);
              if (context.measureText(appName[line]+char).width > canvas.width) {
                line++;
                appName[line] = '';
              }
              appName[line] += char;
            }
            let textTop = (canvas.height+imageSize)/2 + 60;
            for (let k = 0; k < appName.length; k++) {
              context.fillText(appName[k],canvas.width/2,textTop);
              textTop += (48+20);
            }
            context.drawImage(image,(canvas.width-imageSize)/2,(canvas.height-imageSize)/2,imageSize,imageSize);
            let imageString = canvas.toDataURL('image/png');
            let splashRef = this.storage.ref(`${app.shareId}/splashscreen-ios-${value.width}-${value.height}-${value.devicePixelRatio}.png`);
            await splashRef.putString(imageString, 'data_url').then();
            context.clearRect(0,0,canvas.width,canvas.height);
          }
          canvas.remove();
        };
        image.src = '/assets/default_appicon.png';
      }

      const manifestBlob = new File([JSON.stringify(manifest)], "manifest.webmanifest")
      const refManifest = this.storage.ref(`${app.shareId}/manifest.webmanifest`);
      refManifest.put(manifestBlob);

      console.debug(`${this.constructor.name}:createNewApp finished. - ${cells.length} rows.`);
      return new AppModel(app);

    } catch (error) {
      console.warn(`${this.constructor.name}:createNewApp failed`, error);
    }
  }

  async reloadSheetData(userId: string, app: AppModel): Promise<AppModel> {
    await this.gapiService.loadApi();
    const isModifiedAfter = await this.gapiService.compareDocModifiedTime(app.sheetDocId, app.lastSyncTime);
    if (!isModifiedAfter) {
      console.debug(`${this.constructor.name}:reloadSheetData did not run. Because document ${app.sheetDocId} has not been modified after last sync time ${app.lastSyncTime}`);
      return new AppModel(app);
    }

    const tenantId = environment.tanantId;
    const now = moment().format();
    const oldSheetIds = app.sheets.map(function(value){return value.id;});

    const findColumn = (columns: string[], words: string[], startIndex: number, defaultIndex: number) => {
      const index = columns.slice(startIndex).findIndex(col => words.find(word => col.indexOf(word) >= 0) != null);
      if (index >= 0) {
        return columns[index + startIndex];
      }
      return typeof columns[defaultIndex] !== 'undefined'? columns[defaultIndex] : columns[0];
    };
    const infoWindowItems = (columns: string[], oldinfoWindowItems?: InfoWindowItemDef[]) => {
      if (oldinfoWindowItems===null || oldinfoWindowItems.length==0) return [{ column: this.getColName(columns, 6), valueType: 'text' } as InfoWindowItemDef];
      var items = [] as InfoWindowItemDef[];
      for (let item of oldinfoWindowItems) {
        if (findColumn(columns,[item.column],0,0) === item.column) items.push(item);
      }
      if (items.length == 0) items.push({ column: this.getColName(columns, 6), valueType: 'text' } as InfoWindowItemDef);
      return items;
    }

    const remakeSheetEntities = (sheet: SheetMetaData, enable: boolean, oldSheetEntity?: SheetEntity): SheetEntity => {
      const columns = sheet.rows[0];
      const imageColumn = this.findSuggestedColumnWithoutDefault(columns,['画像','image']);
      const sheetDef = {
        position: {
          latColumn: oldSheetEntity!==null && findColumn(columns, [oldSheetEntity.sheetDef.position.latColumn], 0,0) === oldSheetEntity.sheetDef.position.latColumn ? oldSheetEntity.sheetDef.position.latColumn : this.findSuggestedColumn(columns, ['緯度', 'lat', 'y']),
          lonColumn: oldSheetEntity!==null && findColumn(columns, [oldSheetEntity.sheetDef.position.lonColumn], 0,0) === oldSheetEntity.sheetDef.position.lonColumn ? oldSheetEntity.sheetDef.position.lonColumn : this.findSuggestedColumn(columns, ['経度', 'lon', 'x']),
        } as PositionDef,
        marker: {
          markerType: oldSheetEntity!==null ? oldSheetEntity.sheetDef.marker.markerType : 'normal',
          emptyColor: oldSheetEntity!==null ? oldSheetEntity.sheetDef.marker.emptyColor: '#DC143C',
          defaultIconUrlColumn: oldSheetEntity!==null && typeof oldSheetEntity.sheetDef.marker.defaultIconUrlColumn !== 'undefined' ? oldSheetEntity.sheetDef.marker.defaultIconUrlColumn : '',
          items: [
            {
              column: oldSheetEntity!==null && findColumn(columns, [oldSheetEntity.sheetDef.marker.items[0].column], 0,0) === oldSheetEntity.sheetDef.marker.items[0].column ? oldSheetEntity.sheetDef.marker.items[0].column : this.getColName(columns, 0),
              color: oldSheetEntity!==null ? oldSheetEntity.sheetDef.marker.items[0].color : randomColor(),
              trueValue: oldSheetEntity!==null ? oldSheetEntity.sheetDef.marker.items[0].trueValue : '1',
            } as MarkerItemDef,
            {
              column: oldSheetEntity!==null && findColumn(columns, [oldSheetEntity.sheetDef.marker.items[1].column], 0,0) === oldSheetEntity.sheetDef.marker.items[1].column ? oldSheetEntity.sheetDef.marker.items[1].column : this.getColName(columns, 1),
              color: oldSheetEntity!==null ? oldSheetEntity.sheetDef.marker.items[1].color : randomColor(),
              trueValue: oldSheetEntity!==null ? oldSheetEntity.sheetDef.marker.items[1].trueValue : '1',
            } as MarkerItemDef,
            {
              column: oldSheetEntity!==null && findColumn(columns, [oldSheetEntity.sheetDef.marker.items[2].column], 0,0) === oldSheetEntity.sheetDef.marker.items[2].column ? oldSheetEntity.sheetDef.marker.items[2].column : this.getColName(columns, 2),
              color: oldSheetEntity!==null ? oldSheetEntity.sheetDef.marker.items[2].color : randomColor(),
              trueValue: oldSheetEntity!==null ? oldSheetEntity.sheetDef.marker.items[2].trueValue : '1',
            } as MarkerItemDef,
            {
              column: oldSheetEntity!==null && findColumn(columns, [oldSheetEntity.sheetDef.marker.items[3].column], 0,0) === oldSheetEntity.sheetDef.marker.items[3].column ? oldSheetEntity.sheetDef.marker.items[3].column : this.getColName(columns, 3),
              color: oldSheetEntity!==null ? oldSheetEntity.sheetDef.marker.items[3].color : randomColor(),
              trueValue: oldSheetEntity!==null ? oldSheetEntity.sheetDef.marker.items[3].trueValue : '1',
            } as MarkerItemDef,
          ]
        } as MarkerDef,
        infoWindow: {
          titleColumn: oldSheetEntity!==null && findColumn(columns, [oldSheetEntity.sheetDef.infoWindow.titleColumn], 0,0) === oldSheetEntity.sheetDef.infoWindow.titleColumn ? oldSheetEntity.sheetDef.infoWindow.titleColumn : findColumn(columns, ['名', 'title'], 0, 0),
          sub1Column: oldSheetEntity!==null && (findColumn(columns, [oldSheetEntity.sheetDef.infoWindow.sub1Column], 0,0) === oldSheetEntity.sheetDef.infoWindow.sub1Column || oldSheetEntity.sheetDef.infoWindow.sub1Column === '') ? oldSheetEntity.sheetDef.infoWindow.sub1Column : findColumn(columns, ['住所', 'address'], 1, 1),
          sub2Column: oldSheetEntity!==null && (findColumn(columns, [oldSheetEntity.sheetDef.infoWindow.sub2Column], 0,0) === oldSheetEntity.sheetDef.infoWindow.sub2Column || oldSheetEntity.sheetDef.infoWindow.sub2Column === '') ? oldSheetEntity.sheetDef.infoWindow.sub2Column : findColumn(columns, ['電話番号', '電話', 'tel'], 2, 2),
          bottomPanelImage: oldSheetEntity!==null && (findColumn(columns, [oldSheetEntity.sheetDef.infoWindow.bottomPanelImage], 0,0) === oldSheetEntity.sheetDef.infoWindow.bottomPanelImage || oldSheetEntity.sheetDef.infoWindow.bottomPanelImage === '') ? oldSheetEntity.sheetDef.infoWindow.bottomPanelImage : imageColumn,
          items: infoWindowItems(columns, oldSheetEntity!==null ? oldSheetEntity.sheetDef.infoWindow.items : null),
        } as InfoWindowDef,
        search: oldSheetEntity!==null && typeof oldSheetEntity.sheetDef.search!=='undefined' ? oldSheetEntity.sheetDef.search : {
          items: [],
        } as SearchDef,
      } as SheetDef;

      return {
        id: sheet.id,
        icon: oldSheetEntity!==null ? oldSheetEntity.icon : 'account_circle',
        sheetId: sheet.id,
        sheetName: sheet.title,
        name: oldSheetEntity!==null ? oldSheetEntity.name : sheet.title,
        lastSyncTime: now,
        columns: columns,
        initialPosition: oldSheetEntity!==null ? oldSheetEntity.initialPosition : {
          type: 'boundingBox',
          zoom: 15,
        } as InitialPositionDef,
        mapSourceType: oldSheetEntity!==null ? oldSheetEntity.mapSourceType : 'chiriin',
        sheetDef: sheetDef,
        enabled: enable,
      } as SheetEntity
    };

    const spreadSheetData = await this.gapiService.getCellData(app.sheetDocId);

    for (let sheet of Array.from(spreadSheetData.values())) {
      let latColumn: any | string = null;
      let lonColumn: any | string = null;
      for (let sheetEntity of app.sheets) {
        if (sheet.id === sheetEntity.sheetId) {
          latColumn = sheetEntity.sheetDef.position.latColumn;
          lonColumn = sheetEntity.sheetDef.position.lonColumn
        }
      }
      let errorMessage = 'Columns have problem';
      if (typeof sheet.rows === 'undefined') throw new Error(errorMessage);
      this.checkColumnsName(sheet.rows[0],latColumn,lonColumn);
      for (let index = 1; index < sheet.rows.length; index++) {
        if (sheet.rows[index].length > sheet.rows[0].length) throw new Error(errorMessage);
      }
    }

    let enabledSheetsCount = 0;
    const sheetEntities = Array.from(spreadSheetData.values()).map((sheet,index) => {
      let oldSheetEntity: any | SheetEntity = null;
      for (let sheetEntity of app.sheets) {
        if (sheet.id === sheetEntity.sheetId) {
          oldSheetEntity = sheetEntity;
        }
      }
      let enabled = oldSheetEntity!==null ? oldSheetEntity.enabled && enabledSheetsCount < environment.maxSheetCount : enabledSheetsCount < environment.maxSheetCount;
      if (enabled) enabledSheetsCount++;
      return remakeSheetEntities(sheet, enabled, oldSheetEntity);
    });

    app.lastSyncTime = now;
    app.sheets = sheetEntities;

    //const cells = Array.from(spreadSheetData.values())[0].rows;
    //const columns = cells[0];

    let writeCount = 0;
    let batch = this.afs.firestore.batch();
    try {

      for (const sheet of Array.from(spreadSheetData.values())) {
        const sheetRowColl = this.afs.collection(`tenant/${tenantId}/user/${userId}/app/${app.id}/sheet/${sheet.id}/row`);

        const sheetEntity = app.sheets.find(x => x.sheetId === sheet.id);
        const columns = sheetEntity.columns;

        const rows = sheet.rows;

        // そのシートのデータが既に入っていて、現在よりもデータ数が多かった場合その分を削除
        if (oldSheetIds.find(x=>x===sheet.id)!==undefined) {
          const oldRows = await sheetRowColl.get().toPromise();
          for (let index = rows.length; index < oldRows.docs.length+1; index++) {
            const rowId = `row${index.toString().padStart(5, '0')}`;
            const rowDoc = sheetRowColl.doc(rowId).ref;
            batch.delete(rowDoc);
            writeCount++;
            if (writeCount >= 500) {
              await batch.commit();
              writeCount = 0;
              batch = this.afs.firestore.batch();
            }
          }
        }

        for (let index = 1; index < rows.length; index++) {
          const rowId = `row${index.toString().padStart(5, '0')}`;
          const rowDoc = sheetRowColl.doc(rowId).ref;

          const rowData = rows[index].reduce((pre, cur, idx) => {
            pre[columns[idx]] = cur;
            return pre;
          }, {});

          batch.set(rowDoc, rowData);
          writeCount++;
          if (writeCount >= 500) {
            await batch.commit();
            writeCount = 0;
            batch = this.afs.firestore.batch();
          }
        }
      }

      await batch.commit();
      writeCount = 0;
      batch = this.afs.firestore.batch();

      // 削除されたシートのデータを削除
      const removeSheetIds = (oldSheetIds.map(value=>{return app.sheets.find(x=>x.id===value)==undefined ? value : null;})).filter(v=>v);
      for (const oldSheetId of removeSheetIds) {
        const oldRows = await this.afs.collection(`tenant/${tenantId}/user/${userId}/app/${app.id}/sheet/${oldSheetId}/row`).get().toPromise();
        for (const rowDoc of oldRows.docs) {
          batch.delete(rowDoc.ref);
          writeCount++;
          if (writeCount >= 500) {
            await batch.commit();
            writeCount = 0;
            batch = this.afs.firestore.batch();
          }
        }
        batch.delete(this.afs.doc(`tenant/${tenantId}/user/${userId}/app/${app.id}/sheet/${oldSheetId}`).ref);
        writeCount++;
        if (writeCount >= 500) {
          await batch.commit();
          writeCount = 0;
          batch = this.afs.firestore.batch();
        }
      }
      await batch.commit();

      // app.columns = columns;
      const appDoc = this.afs.doc(`tenant/${tenantId}/user/${userId}/app/${app.id}`);
      await appDoc.set(app.toEntity());

      console.debug(`${this.constructor.name}:reloadSheetData finished.`);
      return new AppModel(app);

      // const rows = [];
      // for (let index = 1; index < cells.length; index++) {
      //   const rowId = `row${index.toString().padStart(5, '0')}`;
      //   const rowDoc = rowColl.doc(rowId).ref;

      //   const rowData = cells[index].reduce((pre, cur, idx) => {
      //     pre[columns[idx]] = cur;
      //     return pre;
      //   }, {});

      //   rows.push(rowData);
      // }

      // console.debug(`${this.constructor.name}:reloadSheetData finished. - ${cells.length} rows.`);
      // return { app: new AppModel(app), rows: rows };

    } catch (error) {
      console.warn(`${this.constructor.name}:reloadSheetData failed`, error);
    }
  }

  async createRandomShareId(): Promise<string> {
    const length = 20;
    const firstCharaList = 'abcdefghijklmnopqrstuvwxyz';
    const charaList = firstCharaList+'0123456789';
    while(true) {
      let name = firstCharaList[Math.floor(Math.random()*firstCharaList.length)];
      for (let i=0; i<length-1; i++) {
        name += charaList[Math.floor(Math.random()*charaList.length)];
      }
      if (!(await this.checkShareIdName(name))) return name;
    }
  }

  async checkShareIdName(name: string): Promise<boolean> {
    const shareColl = this.afs.collection(`share`);
    var usedName = ['review','dev'];
    for (const doc of (await shareColl.get().toPromise()).docs) {
      usedName.push(doc.id);
    }
    return usedName.includes(name);
  }

  private getColName(columns: string[], index: number) {
    if (columns.length > index) {
      return columns[index];
    } else {
      return columns[0];
    }
  }

  private findSuggestedColumn(columns: string[], hints: string[]): string {
    for (const hint of hints) {
      const found = columns.findIndex(x => x.toLowerCase().indexOf(hint) >= 0);
      if (found >= 0) {
        return columns[found];
      }
    }

    return columns[0];
  }

  private findSuggestedColumnWithoutDefault(columns: string[], hints: string[]): string {
    for (const hint of hints) {
      const found = columns.findIndex(x => x.toLowerCase().indexOf(hint) >= 0);
      if (found >= 0) {
        return columns[found];
      }
    }

    return '';
  }

  private checkColumnsName(columns: string[], latColumn?: string, lonColumn?: string): any {
    var errorMessage = 'Columns have problem';
    if (columns.length < 2) throw new Error(errorMessage);
    if (columns.includes('')) throw new Error(errorMessage);
    var hints = latColumn!=null ? ['緯度', 'lat', 'y', latColumn.toLocaleLowerCase()]: ['緯度', 'lat', 'y'];
    if (this.findSuggestedColumnWithoutDefault(columns, hints) === '') throw new Error(errorMessage);
    var hints = lonColumn!=null ? ['経度', 'lon', 'x', lonColumn.toLocaleLowerCase()]: ['経度', 'lon', 'x'];
    if (this.findSuggestedColumnWithoutDefault(columns, hints) === '') throw new Error(errorMessage);
    for (let index = 0; index < columns.length; index++) {
      if (columns.indexOf(columns[index],index+1)>=0) throw new Error(errorMessage);
    }
    if (columns.length === 2) throw new Error('There are only lat and lon');
  }
}

export type SheetSearchParams = {
  searchWord: string;
  order: 'name' | 'date';
}