<div class="d-flex  flex-column">
  <div class="d-flex">
    <app-mddb-select
      label="ズームレベル"
      placeholder="ズームレベルを選択"
      [isRequired]="true"
      [outline]="true"
      [options]="zoomLvs"
      [ngModel]="valueStr"
      (ngModelChange)="onValueChange($event)">
    </app-mddb-select>

    <div>
      <ng-container>
        <label class="field-label">&nbsp;</label>
      </ng-container>
      <div class="form-control d-flex align-items-center" [class.text-light]="!onModal"
        style="border-color: transparent; background-color: transparent; padding-left: 0.2rem; padding-right: 0.2rem;">
        <i class="ml-1 icon-button fas fa-walking" (click)="onValueChange(16)"></i>
        <i class="ml-1 icon-button fas fa-bicycle" (click)="onValueChange(14)"></i>
        <i class="ml-1 icon-button fas fa-car-side" (click)="onValueChange(10)"></i>
      </div>
    </div>

    <!-- <div class="d-flex flex-column h-100">
      <label class="field-label">&nbsp;</label>
      <div class="d-flex align-items-center">
        <i class="ml-1 icon-button fas fa-walking" (click)="onValueChange(20)"></i>
        <i class="ml-1 icon-button fas fa-bicycle" (click)="onValueChange(15)"></i>
        <i class="ml-1 icon-button fas fa-car-side" (click)="onValueChange(10)"></i>
      </div>
    </div> -->
  </div>

</div>
