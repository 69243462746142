<div class="bottom-info-panel flex-fill d-flex flex-row px-2" (click)="onInfoWindowModalShow()" style="position: relative; align-items: center;">

  <div class="flex-fill d-flex flex-column" [style.max-width]="bottomImage ? 'calc(100% - 70px)' : '100%'">
    <div style="font-size: 16px;height: 24px;max-width: 100%;">
      <strong style="display: block;overflow: hidden;max-width: 100%;white-space: nowrap;text-overflow: ellipsis;">
        {{bottomInfoTitle}}
      </strong>
    </div>
    <div style="opacity: 0.7;font-size: 14px;height: 21px;overflow: hidden;max-width: 100%;white-space: nowrap;text-overflow: ellipsis;">
      {{bottomInfoSub1}}
    </div>
    <div style="opacity: 0.7;font-size: 14px;height: 21px;overflow: hidden;max-width: 100%;white-space: nowrap;text-overflow: ellipsis;">
      {{bottomInfoSub2}}
    </div>
  </div>
  <div *ngIf="bottomImage" style="height: 60px; width: 60px; margin-left: 10px; display: flex; align-items: center; outline-style: solid; outline-width: 1px;">
    <img [src]="bottomImage" style="width: 100%; height: auto; object-fit: contain; max-height: 100%;"/>
  </div>

</div>
