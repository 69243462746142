<!-- <div class="modal-dialog modal-dialog-centered" role="document"> -->
  <div class="modal-content">
    <div class="modal-header info-window-header"
    >
      <h4 class="modal-title w-100" id="myModalLabel">{{ title }}</h4>

      <button type="button" class="close" aria-label="Close" (click)="onNoClick()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body info-window-body">
      <app-mddb-select
        [outline]="true"
        [options]="options"
        [ngModel]="value"
        placeholder="地図の種類を選択"
        (ngModelChange)="onValueChange($event)">
      </app-mddb-select>
    </div>
    <div class="modal-footer info-window-footer">
      <button type="button" class="relative btn smb-primary-button" (click)="onYesClick()"
      >決定</button>
      <button type="button" ngbAutofocus class="btn smb-secondary-button" aria-label="Close" (click)="onNoClick()"
      >キャンセル</button>
    </div>
  </div>
<!-- </div> -->
