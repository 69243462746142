<ng-container *ngIf="label != null && label != ''">
  <label [for]="id" class="field-label">{{label}}</label>
</ng-container>
<div [id]="id" class="form-control d-flex justify-content-center"
    style="cursor: pointer;"
    [iconPicker]="ngModel"
    [ipIconPack]="packs"
    [ipPosition]="'bottom'"
    [ipWidth]="'250px'"
    [ipIconSize]="'28px'"
    [ipIconVerticalPadding]="'6px'"
    [ipIconHorizontalPadding]="'10px'"
    [ipKeepSearchFilter]="'false'"
    [ipPlaceHolder]="'アイコンを選択'"
    [ipFallbackIcon]="fallbackIcon"
    (iconPickerSelect)="onIconPickerSelect($event)">
    <i class="material-icons">{{ngModel}}</i>
</div>
