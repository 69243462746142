import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-map-source-select',
  templateUrl: './map-source-select.component.html',
  styleUrls: ['./map-source-select.component.scss']
})
export class MapSourceSelectComponent {
  @Input() value: string;
  @Output() readonly valueChange = new EventEmitter<string>();

  readonly mapSources: { value: string, label: string }[] = [
    { value:  'chiriin', label:  '地理院地図' },
    { value:  'osm', label:  'OpenStreetMap' },
  ];


  constructor() { }

  ngOnInit() {
  }

  onValueChange(event: string) {
    console.debug(`${this.constructor.name}:onValueChange called.`, event);
    this.value = event;
    this.valueChange.emit(this.value);
  }

}
