import { Component, OnInit, HostBinding, HostListener } from '@angular/core';
import { AppThemeDef } from 'src/app/usecase/sheet-usecase.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as color from 'color';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info-window-modal',
  templateUrl: './info-window-modal.component.html',
  styleUrls: ['./info-window-modal.component.scss']
})
export class InfoWindowModalComponent implements OnInit {
  theme: AppThemeDef;
  title = '';
  gotoMapApp: ()=>void;
  gotoPhoneApp: (string)=>void;
  gotoOtherPage: (string)=>void;

  modalHeight = '85vh';

  sub1 = {
    title: 'aaa',
    value: 'xxx'
  };
  sub2 = {
    title: 'bbb',
    value: 'yyy'
  };

  items: { title: string, value: string, valueType?: string }[] = [
    { title: 'item1', value: 'v2a' },
    { title: 'item2', value: 'v2b' },
    { title: 'item3', value: 'v2c' },
  ];

  phoneRegExp = /^[(（]?[0０][0-9０-９]{2,3}[)）\-‐－―ー]?[\s　]?[0-9０-９]{1,4}[\-‐－―ー]?[0-9０-９]{4}$/;
  urlRegExp = /^https?:\/\/[\w!\?/\+\-_~=;:\.,\*&@#\$%\(\)'\[\]]+$/;

  @HostBinding("attr.style")
  public get valueAsStyle(): any {
    const getForeColor = (bk) => {
      if (color(bk).luminosity() < 0.5) {
        return '#FFFFFF';
      } else {
        return '#000000';
      }
    }

    return this.sanitizer.bypassSecurityTrustStyle(`
      --theme-background-color: ${this.theme.backgroundColor};
      --theme-foreground-color: ${getForeColor(this.theme.backgroundColor)};
      --theme-splash-background-color: ${this.theme.splashBackgroundColor};
      --theme-splash-foreground-color: ${getForeColor(this.theme.splashBackgroundColor)};
      --theme-button-primary-background-color: ${this.theme.buttonPrimaryBackgroundColor};
      --theme-button-primary-foreground-color: ${getForeColor(this.theme.buttonPrimaryBackgroundColor)};
      --theme-button-secondary-background-color: ${this.theme.buttonSecondaryBackgroundColor};
      --theme-button-secondary-foreground-color: ${getForeColor(this.theme.buttonSecondaryBackgroundColor)};
      --theme-header-background-color: ${this.theme.headerBackgroundColor};
      --theme-header-foreground-color: ${getForeColor(this.theme.headerBackgroundColor)};
      --theme-footer-background-color: ${this.theme.footerBackgroundColor};
      --theme-footer-foreground-color: ${getForeColor(this.theme.footerBackgroundColor)};
    `);
  }


  constructor(
    public activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    ) {
  }

  ngOnInit() {
    this.onResized();
  }

  onCloseClick() {
    this.activeModal.dismiss();
  }

  phoneNumberReplace(str: string): string {
    return str.replace(/[‐－―ー]/g, '-').replace(/[(（)）\s　]/g,'').replace(/[０-９]/g,function(s){return String.fromCharCode(s.charCodeAt(0) - 65248);});
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.onResized();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationchange(event) {
    this.onResized();
  }

  onResized() {
    this.modalHeight = window.innerHeight * 0.95 + 'px';
  }
}
