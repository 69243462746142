<div class="card d-flex flex-column" style="width: 200px; height: 150px; border-color: #43A047;">

  <div class="flex-grow-1 my-2 mx-3">
    <div style="width: 40px; height: 40px; line-height: 30px;  font-size: 40px; text-align: center;">+</div>
    <h6>スプレッドシートから新規アプリを作成</h6>
  </div>

  <div class="bg-white rounded-bottom">
    <hr class="mt-0 mb-0"/>
    <div class="d-flex flex-column my-2 mx-3">
      <small class="text-secondary align-self-end"></small>
    </div>
  </div>

</div>
