import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-column-select',
  templateUrl: './column-select.component.html',
  styleUrls: ['./column-select.component.scss']
})
export class ColumnSelectComponent implements OnInit {
  @Input() title = '';
  @Input() placeholder = '';
  // @Input() allowNoneSelection = false;
  @Input() isRequired = false;
  @Input('columns')
  set columnsSetter(value: string[]) {
    // const opts = value.map(x => {
    this.options = value.map(x => {
      return { value: x, label: x };
    });

    /*if (this.allowNoneSelection) {
      this.options = [{ value: '', label: this.placeholder }, ...opts];
    } else {
      this.options = opts;
    }*/

    const org = this.value;
    this.value = '';
    this.ref.detectChanges();
    this.value = org;
    this.ref.detectChanges();
  }
  @Input() value = '';
  @Output() readonly valueChange = new EventEmitter<string>();

  options: { value: string, label: string }[] = [];

  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit() {
  }

  onValueChange(event) {
    this.value = event;
    this.valueChange.emit(this.value);
  }
}
