import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from "@angular/platform-browser";
import { AuthUsecase, UserEntity } from 'src/app/usecase/auth-usecase.service';
import { SheetUsecase, SpreadsheetModel as ShpeadsheetModel, AppEntity, InitialPositionDef, SheetSearchParams } from 'src/app/usecase/sheet-usecase.service';
import { AppUsecase, AppModel } from 'src/app/usecase/app-usecase.service';
import { SheetSearchModalService } from 'src/app/ui/modal/sheet-search-modal/sheet-search-modal.service';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.scss']
})
export class AppListComponent implements OnInit {

  apps: AppModel[] = [];

  sheets: ShpeadsheetModel[] = [];

  get user(): UserEntity {
    return this.authUsecase.user;
  }

  loading = true;
  
  remainingError: number = null;

  //searchWord = '';
  searchParams = {
    searchWord: '',
    order: 'date'
  }as SheetSearchParams 

  constructor(
    private router: Router,
    private titleService: Title,
    private authUsecase: AuthUsecase,
    private appUsecase: AppUsecase,
    private sheetUsecase: SheetUsecase,
    private sheetSearchModal: SheetSearchModalService,
    private toastr: ToastrService,
  ) { }

  async ngOnInit() {
    this.titleService.setTitle('どすごいマップ for spreadsheet');
    if (location.href.includes(environment.domain.user)) {
      this.router.navigate(['/share_map_page']);
    }

    try {
      this.apps = await  this.appUsecase.listApps(this.authUsecase.user.uid);

      this.sheets = await this.sheetUsecase.listDocuments(this.searchParams.searchWord);
      console.debug(`${this.constructor.name}:ngOnInit finished.`, this.sheets);
    } catch (error) {
      console.warn(`${this.constructor.name}:ngOnInit failed.`, error);
      if (error.message==='Not signed in to Google') this.toastr.error('Googleアカウントにログインしていません。<br/><b>クリックするとログイン画面に戻ります。</b>',null,{
        enableHtml: true,
        positionClass: 'toast-bottom-full-width',
        closeButton: true
      }).onTap.subscribe(()=>{this.router.navigate(['/start'])});
    } finally {
      this.loading = false;
    }
  }

   async onAppClick(item: AppModel) {
    this.clearRemainingError();
    try {
      this.loading = true;

      item.lastAccessTime = moment().format();

      // Migration
      if (!item.author) { item.author = ''; }
      if (!item.icon) { item.icon = ''; }
      if (!item.description) { item.description = ''; }
      if (!item.themeType) { item.themeType = 'light_default'; }
      if (!item.owner) { item.owner = this.authUsecase.user.uid; }
      if (!item.shareId) { item.shareId = ''; }

      await this.appUsecase.saveApp(this.authUsecase.user.uid, item);
      this.router.navigate(['/map_page', { app_id: item.id } ]);
    } catch (error) {
      console.error(`${this.constructor.name}:onAppClick failed.`, error);
    } finally {
      this.loading = false;
    }
  }

  async onSheetSearchClick() {
    this.sheetSearchModal.show(this.sheets,this.searchParams,this.toastr,()=>this.onSort(),()=>this.onSearchSheet(),(item)=>this.onSheetClick(item), this.clearRemainingError);
  }

  onSort() {
    let order = this.searchParams.order;
    this.sheets.sort(function(a,b) {
      if (order=='date') {
        return a.modifiedTimeMoment.isAfter(b.modifiedTimeMoment) ? -1 : 1;
      } else {
        return a.name > b.name ? 1 : -1;
      }
    });
  }

  async onSearchSheet() {
    this.loading = true;
    this.clearRemainingError();
    try {
      this.sheets = await this.sheetUsecase.listDocuments(this.searchParams.searchWord);
      if (this.searchParams.order!='date') this.sheets.sort(function(a,b){return a.name > b.name ? 1 : -1;});
      this.sheetSearchModal.updateSheets(this.sheets);
    } catch (error) {
      console.warn(`${this.constructor.name}:onSearchSheet failed.`, error);
      if (error.message==='Not signed in to Google') this.toastr.error('Googleアカウントにログインしていません。<br/><b>クリックするとログイン画面に戻ります。</b>',null,{
        enableHtml: true,
        positionClass: 'toast-bottom-full-width',
        closeButton: true
      }).onTap.subscribe(()=>{
        this.router.navigate(['/start']);
        this.sheetSearchModal.modalRef?.close();
      });
    } finally {
      this.loading = false;
    }

  }

  async onSheetClick(item: ShpeadsheetModel): Promise<boolean> {
    this.loading = true;
    this.clearRemainingError();
    try {
      const pos = window.location.href.indexOf(this.router.url)
      //const baseUrl = window.location.href.substring(0, pos);

      const app = await this.sheetUsecase.createNewApp(this.authUsecase.user.uid, item/*, baseUrl*/);
      this.apps = [app, ...this.apps];

      // this.router.navigate(['/map_page']);
      await this.onAppClick(app);
      return true;
    } catch (error) {
      console.warn(`${this.constructor.name}:onSheetClick failed.`, error);
      if (error.message==='Not signed in to Google') this.toastr.error('Googleアカウントにログインしていません。<br/><b>クリックするとログイン画面に戻ります。</b>',null,{
        enableHtml: true,
        positionClass: 'toast-bottom-full-width',
        closeButton: true
      }).onTap.subscribe(()=>{
        this.router.navigate(['/start']);
        this.sheetSearchModal.modalRef?.close();
      });
      else if (error.message==='Columns have problem') this.remainingError = this.toastr.error('スプレッドシートに以下のいずれかの問題があります。修正してからアプリの作成をやり直してください。<br>　・緯度や経度に該当する列がない<br>　・1行目の列名が重複している<br>　・1行目の列名が空欄となっている',null,{
        enableHtml: true,
        positionClass: 'toast-bottom-full-width',
        disableTimeOut: true,
        closeButton: true
      }).toastId;
      else if (error.message==='There are only lat and lon') this.remainingError = this.toastr.error('緯度経度しかないデータではアプリを作成できません。<br>地点の名前などの付加情報を入れてください。',null,{
        enableHtml: true,
        positionClass: 'toast-bottom-full-width',
        disableTimeOut: true,
        closeButton: true
      }).toastId;
      return false;
    } finally {
      this.loading = false;
    }
  }

  clearRemainingError = () => {
    if (this.remainingError!==null) {
      this.toastr.clear(this.remainingError);
      this.remainingError = null;
    }
  }

  async logout() {
    this.clearRemainingError();
    await this.authUsecase.signOut();
    this.router.navigate(['/redirect']);
  }

}
