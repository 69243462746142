<div class="container-fluid">
  <div class="row my-2">

    <div class="col-12 mt-2">
      <h5>位置</h5>
    </div>

    <div class="col-6 mt-2">
      <app-column-select title="緯度" placeholder="緯度の列を選択してください"
        [columns]="columns" [(value)]="positionDef.latColumn" (valueChange)="onDirty(true)"></app-column-select>
    </div>

    <div class="col-6 mt-2">
      <app-column-select title="経度" placeholder="経度の列を選択してください"
        [columns]="columns" [(value)]="positionDef.lonColumn" (valueChange)="onDirty(true)"></app-column-select>
    </div>

    <div class="col-12 mt-4 d-flex justify-content-between align-items-center">
      <h5>マーカー</h5>
      <button hidden type="button" class="btn btn-sm btn-outline-secondary" (click)="onMarkerItemDefAdd()"><i class="fas fa-plus"></i></button>
    </div>

    <div class="col-8 mt-2">
      <app-mddb-select
        [outline]="true"
        [options]="markerTypes"
        [(ngModel)]="sheetDef.marker.markerType"
        (ngModelChange)="onDirty(true)"
        [isRequired]="true"
        placeholder="マーカーの種類を選択"
        [label]="'マーカーの種類'">
      </app-mddb-select>
    </div>

    <div class="col-4 mt-2">

      <app-preset-color-field
        *ngIf="sheetDef.marker.markerType == 'normal'"
        label="既定色"
        [(ngModel)]="sheetDef.marker.emptyColor"
        (ngModelChange)="onDirty(true)"
      ></app-preset-color-field>

      <app-color-field
        *ngIf="sheetDef.marker.markerType == 'thematic'"
        label="既定色"
        [(ngModel)]="sheetDef.marker.emptyColor"
        (ngModelChange)="onDirty(true)"
      ></app-color-field>

      <!-- <app-empty-marker-select
        [(color)]="sheetDef.marker.emptyColor"
        (colorChange)="onDirty(true)"
      ></app-empty-marker-select> -->

    </div>

    <div *ngIf="sheetDef.marker.markerType == 'normal'" class="col-12 mt-3">
      <app-column-select
        title="アイコン画像"
        placeholder="列を選択してください"
        [(value)]="sheetDef.marker.defaultIconUrlColumn"
        (valueChange)="onDirty(true)"
        [columns]="columns"
        [isRequired]="false"
      ></app-column-select>
    </div>

    <div *ngIf="sheetDef.marker.markerType == 'thematic'" class="col-12 mt-4">
      <div *ngFor="let item of markerDefs; let i = index"
        class="mb-1">
        <app-marker-column-select
          placeholder="列を選択してください"
          [no]="i+1"
          [value]="item"
          (valueChange)="onMarkerDefChange(i, $event)"
          [columns]="columns"
          (delete)="onMarkerDefDelete(i, item)"
          [reservedSaveCount]="reservedSaveCount"
        ></app-marker-column-select>
      </div>
    </div>

    <div class="col-12 mt-4">
      <h5>情報表示</h5>
    </div>

    <div class="col-12 mt-1">
      <app-column-select title="タイトル" placeholder="タイトルの列を選択してください"
        [columns]="columns" [(value)]="infoWindowDef.titleColumn" (valueChange)="onDirty()" [isRequired]="true"></app-column-select>
    </div>

    <div class="col-12 mt-2">
      <app-column-select title="見出し１" placeholder="見出し１の列を選択してください"
        [columns]="columns" [(value)]="infoWindowDef.sub1Column" (valueChange)="onDirty()" [isRequired]="false"></app-column-select>
    </div>

    <div class="col-12 mt-2">
      <app-column-select title="見出し２" placeholder="見出し２の列を選択してください"
        [columns]="columns" [(value)]="infoWindowDef.sub2Column" (valueChange)="onDirty()" [isRequired]="false"></app-column-select>
    </div>

    <div class="col-12 mt-2">
      <app-column-select title="画像" placeholder="画像の列を選択してください"
        [columns]="columns" [(value)]="infoWindowDef.bottomPanelImage" (valueChange)="onDirty()" [isRequired]="false"></app-column-select>
    </div>

    <div class="col-12 mt-4 d-flex justify-content-between align-items-center">
      <h6>詳細項目</h6>
      <button type="button" class="btn btn-sm btn-outline-secondary" (click)="onInfoWindowItemDefAdd()"><i class="fas fa-plus"></i></button>
    </div>

    <div *ngFor="let item of infoWindowItemDefs; let i = index" class="col-12 mt-2">
      <!-- <div class=""> -->
        <!-- <div class="text-right mr-2" style="flex-basis: 30px;"><small>{{i+1}}.</small></div> -->
        <app-info-window-column-select
          placeholder="列を選択してください"
          [no]="i+1"
          [value]="item"
          (valueChange)="onInfoWindowItemDefChange(i, $event)"
          [columns]="columns"
          (delete)="onInfoWindowItemDefDelete(i, item)"
        ></app-info-window-column-select>

    </div>

    <div class="col-12 mt-4 d-flex justify-content-between align-items-center">
      <h6>検索項目</h6>
      <button type="button" class="btn btn-sm btn-outline-secondary" (click)="onSearchItemDefAdd()"><i class="fas fa-plus"></i></button>
    </div>

    <div *ngFor="let item of searchItemDefs; let i = index" class="col-12 mt-2">
      <!-- <div class=""> -->
        <!-- <div class="text-right mr-2" style="flex-basis: 30px;"><small>{{i+1}}.</small></div> -->
        <app-search-column-select
          placeholder="列を選択してください"
          [no]="i+1"
          [value]="item"
          (valueChange)="onSearchItemDefChange(i, $event)"
          [columns]="columns"
          (delete)="onSearchItemDefDelete(i, item)"
        ></app-search-column-select>

    </div>

  </div>
</div>
