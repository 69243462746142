<div class="d-flex align-items-center flex-wrap">
  <app-initial-position-type-select
    class="flex-fill"
    [(value)]="initialPositionType"
  ></app-initial-position-type-select>

  <app-initial-position-lat-lng-setting class="ml-2"
    *ngIf="initialPositionType === 'latLonZoom'"
    [(value)]="latLonZoomDef"
    [zoomLevel]="zoomLevel"
    (zoomLevelChange)="zoomLevel = $event"
    (applyMapPositionRequest)="onApplyMapPositionRequest()"
    [onModal]="onModal"
    [reservedSaveCount]="reservedSaveCount"
  ></app-initial-position-lat-lng-setting>

  <app-zoom-level-select class="ml-2"
    *ngIf="initialPositionType === 'gps'"
    [(value)]="zoomLevel"
  ></app-zoom-level-select>
</div>
