<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px', fullScreenBackdrop: true }"></ngx-loading>

<div *ngIf="!initialDataLoaded" class="d-flex align-items-center justify-content-center smb-splash"
  [style.background-color]="splashBackgroundColor" style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;"
  >
  <div class="text-center">

    <img style="width: 50px; height: 50px;" [src]="appImage">

    <h2 class="card-title h1-responsive pt-2 mb-5 font-bold"><strong>{{splashAppTitle}}</strong></h2>
    <h4 class="">
      読み込んでいます．．．
    </h4>
  </div>
</div>


<div *ngIf="initialDataLoaded" class="d-flex flex-column" style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;">

  <!-- タイトルバー -->
  <div
    *ngIf="!showSideBar"
    class="p-3 smb-app-bar"
    style="flex-basis: 56px; display: flex; height: 59px;"
  >
    <a class="mr-2" (click)="toggleSideNav()"><i class="fas fa-bars text-light"></i></a>
    <span style="text-overflow: ellipsis;overflow: hidden; white-space: nowrap; display: block;">{{app.name}}</span>
  </div>

  <div class="flex-fill d-flex">

    <div *ngIf="showSideBar" class="map-side-bar d-flex flex-column bg-primary text-light" style="min-width: 250px; max-width: 250px; flex-basis: 250px; z-index: 2;">
      <app-app-setting-panel
        [app]="app"
        [sheet]="sheet"
        [currentCamera]="currentCamera"
        [(selectedSheetId)]="selectedSheetId"
        [(selectedSheetEnabled)]="selectedSheetEnabled"
        [deleteAppPromise]="onDeleteApp"
        (backClick)="back()"
        (onDirtyChange)="onDirty($event)"
        (reloadClick)="onReload()"
        (requestMapPosition)="onRequestMapPosition($event)"
        (clearRemainingError)="clearRemainingError()"
        [reservedSaveCount]="reservedSaveCount"
        (initGps)="onClickDetermineLocation()"
        (moveToAllMarker)="onMoveToAllMarker()"
        (imageUploadStart)="imageUploadStart()"
        (imageUploadFinish)="imageUploadFinish()"
      ></app-app-setting-panel>

    </div>

    <div class="flex-fill" [style.max-width]="showSideBar ? 'calc(100% - 650px)' : '100%'">

      <div
        class=""
        [style.height.px]="map_height"
      >
        <app-map-view
          #mapView

          [hidden]="mapOrListIconClass == 'fa-map'"
          [sheetDef]="sheetDef"
          [rows]="filteredRows"
          [initialPos]="initialPos"
          [defaultMarkerColor]="emptyMarkerColor"
          [mapSourceType]="sheet.mapSourceType"
          [initialPosition]="sheet.initialPosition"
          [fitBounds]="fitBounds"
          [preventFromSelectedSheetChange]="preventFromSelectedSheetChange"
          (loaded)="onMapLoaded()"
          (moved)="onMapMoved($event)"
          (clicked)="onMapAreaClicked()"
          (selectedMarkerChange)="onSelectedMarkerChange($event)"
          [(changeMapSourceTypeCommand)]="changeMapSourceType"
          [(moveToCommand)]="moveTo"
          [(fitBoundsCommand)]="fitBoundsCommand"
        ></app-map-view>

        <ng-container
          *ngIf="mapOrListIconClass == 'fa-map'">

          <div style="position:relative; width: 100%; height: 100%; overflow-y: auto;" (click)="onMapAreaClicked()">
            <div style="width: 100%;">
              <div style="margin-bottom: 1rem;"></div>
              <div *ngFor="let row of orderedFilteredRows; let i = index;">
                <app-row-summary-panel
                  [theme]="theme"
                  [sheetDef]="sheetDef"
                  [selectedRow]="row"
                  [isMobile]="isMobile"
                  [isAndroid]="isAndroid"
                  (clearRemainingError)="clearRemainingError()"
                ></app-row-summary-panel>
                <hr class="hr-dark">
              </div>
            </div>
          </div>

        </ng-container>

        <div style="position: absolute; left: 0; right: 0;">
          <app-bottom-panel
            [theme]="theme"
            [sheetDef]="sheetDef"
            [selectedRow]="selectedRow"
            [contentHeight]="bottomInfoPanelHeight"
            [showSideBar]="showSideBar"
            [isMobile]="isMobile"
            [isAndroid]="isAndroid"
          ></app-bottom-panel>
        </div>

      </div>

      <div id="bottom-search-panel" class="bottom-info-panel d-flex flex-column px-2"
        [style.left.px]="leftBarWidth"
        [style.right.px]="rightBarWidth"
        [style.bottom.px]="'60'"
        [style.height.px]="bottomSearchPanelHeight"
      >
        <div class="p-2">
          <app-search-panel
            [listItemMap]="listItemMap"
            [value]="sheet.sheetDef.search"
            [resultMap]="resultMap"
          ></app-search-panel>
        </div>
        <div class="px-2 d-flex justify-content-center">
          <button type="button" class="col-6 relative waves-light btn smb-secondary-button" (click)="onSearchClear()">
            クリア
          </button>
          <button type="button" class="col-6 ml-1 relative waves-light btn smb-secondary-button" (click)="onSearchApply()">
            決定
          </button>
        </div>
      </div>

      <div style="height: 60px; z-index: 999;"

        class="d-flex justify-content-between smb-app-bottom-panel"
      >
        <div class="d-flex justify-content-center align-items-center flex-column"
          style="cursor: pointer; width: 100%;"
          *ngFor="let sheet of enabledSheets; let i = index;"
          [ngClass]="sheet.id == selectedSheetId ? 'smb-bottom-tab-selected' : ''"
          (click)="selectedSheetId = sheet.id"
          >
          <i class="material-icons">{{sheet.icon}}</i>
          <small>{{sheet.name}}</small>
        </div>

      </div>

    </div>

    <div *ngIf="showSideBar" style="min-width: 400px; max-width: 400px; flex-basis: 400px; overflow-y: auto; height: 100vh;">

      <app-sheet-setting-panel *ngIf="selectedSheetEnabled"
        [sheet]="sheet"
        [sheetDef]="sheetDef"
        (onDirtyChange)="onDirty($event)"
        [reservedSaveCount]="reservedSaveCount"
      ></app-sheet-setting-panel>

    </div>
  </div>

</div>

<ng-container *ngIf="initialDataLoaded">

  <app-fav
    iconClass="fa-search"
    position="bottomLeft"
    [offsetLeft]="favOffsetLeft"
    [offsetBottom]="favOffsetBottom"
    (click)="onClickSearch()"
  ></app-fav>

  <app-fav
    [iconClass]="mapOrListIconClass"
    position="topRight"
    [offsetRight]="favOffsetRight"
    [offsetTop]="favOffsetTop"
    (click)="onClickList()"
  ></app-fav>

  <app-fav
    *ngIf="mapOrListIconClass == 'fa-list'"
    class="bottom-fav"
    iconClass="fa-location-arrow"
    position="bottomRight"
    [offsetRight]="favOffsetRight"
    [offsetBottom]="favOffsetBottom"
    (click)="onClickDetermineLocation()"
  ></app-fav>

  <app-fav
    *ngIf="mapOrListIconClass != 'fa-list'"
    class="bottom-fav"
    [iconClass]="searchOrderIsAsc ? 'fa-sort-amount-down-alt' : 'fa-sort-amount-down'"
    position="bottomRight"
    [offsetRight]="favOffsetRight"
    [offsetBottom]="favOffsetBottom"
    (click)="searchOrderIsAsc = !searchOrderIsAsc"
  ></app-fav>

  <!-- Sidebar [hidden]="!showSideNav"-->
  <div  class="fixed side-nav"
    [style.width.rem]="sideNavWidth"
    >
    <div class="p-2 smb-app-sidebar d-flex flex-column">
      <button type="button" class="align-self-end close text-light" aria-label="Close" (click)="toggleSideNav()">
        <span aria-hidden="true">&times;</span>
      </button>

      <h4 class="text-light">
        <i class="material-icons" style="font-size: 2.5rem;">{{app.icon}}</i>
      </h4>
      <h4 class="text-light">
        {{app.name}}
      </h4>
      <div class="mt-2 text-light">作者:</div>
      <div class="ml-1 text-light">{{app.author}}</div>
      <div class="mt-2 text-light">アプリの説明:</div>
      <div class="ml-1 text-light">{{app.description}}</div>
    </div>
  </div>


  <!-- TODO Sidebar navigation
  <mdb-side-nav [hidden]="showSideBar" #sidenav class="fixed" [fixed]="true">

    <div class="p-4 smb-app-sidebar">
      <h4>
        <i class="material-icons" style="font-size: 2.5rem;">{{app.icon}}</i>
      </h4>
      <h4>
        {{app.name}}
      </h4>
      <div class="mt-2">作者:</div>
      <div class="ml-1">{{app.author}}</div>
      <div class="mt-2">アプリの説明:</div>
      <div class="ml-1">{{app.description}}</div>
    </div>


  </mdb-side-nav> -->
  <!--/. Sidebar navigation -->

</ng-container>


