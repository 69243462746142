import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthUsecase } from '../usecase/auth-usecase.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private appUsecase: AuthUsecase,
    private router: Router) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    console.log(`${this.constructor.name}: AuthGuard -> canActivate`);

    const isAdmin = next.paramMap.get('share') != '1' && next.url[0].path !== 'share_map_page';
    if (isAdmin) {
      if (this.appUsecase.isAdminSignedIn) {
        return true;
      }

      this.router.navigate(['/redirect']);
      return false;
    } else {
      if (this.appUsecase.isSignedIn) {
        return true;
      }

      return await this.appUsecase.signInForShare();
    }
  }
}
