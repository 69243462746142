<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title w-100" id="myModalLabel">スプレッドシートからアプリを新規作成</h4>

    <button type="button" class="close" aria-label="Close" (click)="onCloseClick()">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>
  <div class="modal-body container-fluid">
    <div class="row">
      <div class="col-8">
        <div class="row">

          <div class="col-6">
            <form class="input-group input-group-sm">
              <input class="form-control flex-fill" type="text"
                [ngModelOptions] = "{standalone: true}" (keyup)="onSearchSheetByEnter($event)"
                placeholder="キーワードで検索" [(ngModel)]="searchParams.searchWord">

              <div class="input-group-append" id="button-addon4">
                <button class="btn btn-outline-secondary" type="button" (click)="onSearchSheet()">
                  <svg width="0.8em" height="0.8em" viewBox="0 0 16 16" class="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                    <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                  </svg>
                </button>
              </div>
            </form>
          </div>

          <div class="col-4">
            <app-sheet-search-order-select
            [(value)]="searchParams.order"
            (valueChange)="onSort(false)"
            ></app-sheet-search-order-select>
          </div>

        </div>

      </div>

      <div class="col-12 mt-2" style="height: 60vh; overflow-y: auto;">
        <table class="table" style="cursor: default;">
          <thead>
            <tr>
              <th scope="col">スプレッドシート名{{indicateOrderName}}</th>
              <th scope="col">オーナー</th>
              <th scope="col">更新日時{{indicateOrderTime}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sheet of sheets" [class.selected-sheet]="selectedSheet&&sheet.id==selectedSheet.id" (click)="onSheetClick(sheet)">
              <td>
                <img src="https://img.icons8.com/color/48/000000/google-sheets.png" style="height: 30px; width: auto;">
                {{sheet.name}}
              </td>
              <td>{{sheet.owner}}</td>
              <td>{{sheet.modifiedTimeFormated}}</td>
            </tr>
          </tbody>
        </table>
      </div>


    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="col-2 btn btn-secondary" aria-label="Create" (click)="onCreateApp()">作成</button>
    <button type="button" ngbAutofocus class="col-2 btn btn-primary" aria-label="Close" (click)="onCloseClick()">閉じる</button>
  </div>
</div>
