import { Injectable } from '@angular/core';

import { GApiService } from 'src/app/service/gapi.service';
import { Moment } from 'moment';
import moment from 'moment';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import * as randomColor from 'randomcolor';
import { AppEntity, AppDef, InitialPositionDef, MapSourceType, AppThemeDef, ThemeType, SpreadsheetModel, SheetEntity } from './sheet-usecase.service';
import { AngularFireStorage } from '@angular/fire/storage';

export class SheetModel implements SheetEntity {
  id: string;
  icon: string;
  sheetId: string;
  sheetName: string;
  name: string;
  lastSyncTime: string;
  columns: string[];
  sheetDef: AppDef;
  initialPosition: InitialPositionDef;
  mapSourceType: MapSourceType;
  enabled: boolean;

  constructor(init?: Partial<SheetEntity>) {
    if (init != null) {
      this.id = init.id;
      this.sheetId = init.sheetId;
      this.icon = init.icon;
      this.name = init.name;
      this.sheetName = init.sheetName;
      this.lastSyncTime = init.lastSyncTime;
      this.columns = init.columns != null ? [...init.columns] : null;
      this.initialPosition = init.initialPosition != null ? Object.assign({}, init.initialPosition) : null;
      this.mapSourceType = init.mapSourceType;
      this.enabled = init.enabled;
    }
  }
}

export class AppModel implements AppEntity {
  id: string;
  shareId: string;
  icon: string;
  sheetDocId: string;
  name: string;
  description: string;
  author: string;
  lastSyncTime: string;
  lastAccessTime: string;
  color: string;
  themeType: ThemeType;
  theme: AppThemeDef;
  sheets: SheetEntity[];
  owner: string;

  // columns: string[], // move to SheetEntity
  // appDef: AppDef, // move to SheetEntity
  // initialPosition: InitialPositionDef, // move to SheetEntity
  // mapSourceType: MapSourceType, // move to SheetEntity

  constructor(init?: Partial<AppEntity>) {
    if (init != null) {
      this.id = init.id;
      this.shareId = init.shareId;
      this.icon = init.icon;
      this.name = init.name;
      this.description = init.description;
      this.author = init.author;
      this.sheetDocId = init.sheetDocId;
      this.lastSyncTime = init.lastSyncTime;
      this.lastAccessTime = init.lastAccessTime;
      this.color = init.color;
      this.themeType = init.themeType;
      this.theme = init.theme != null ? Object.assign({}, init.theme) : null;
      this.sheets = init.sheets != null ? [...init.sheets] : [];
      this.owner = init.owner;
    }
  }

  get sheetModels(): SheetModel[] {
    if (this.sheets == null) {
      return [];
    }
    return this.sheets.map(x => {
      return new SheetModel(x);
    });
  }


  get lastAccessTimeFormated(): string {
    return moment(this.lastAccessTime).format('YYYY/MM/DD');
  }

  toEntity(): AppEntity {
    return Object.assign({} as AppEntity, this);
  }

}

@Injectable({ providedIn: 'root' })
export class AppUsecase {
  constructor(
      private gapiService: GApiService,
      private afs: AngularFirestore,
      private storage: AngularFireStorage,
  ) {
  }

  async listApps(userId: string): Promise<AppModel[]> {
    const tenantId = environment.tanantId;
    this.afs.firestore.collection(`users`);
    const appColl = this.afs.collection<AppEntity>(`tenant/${tenantId}/user/${userId}/app`, ref => ref.orderBy('lastAccessTime', 'desc'));
    const apps = await appColl.get().toPromise();
    return apps.docs.map(x => new AppModel(x.data() as AppEntity));
  }

  async loadAppByShareId(shareId: string): Promise<AppModel>{
    const shareRef = this.afs.doc<AppEntity>(`share/${shareId}`);
    const shareEntity = (await shareRef.ref.get()).data();
    if (typeof shareEntity === "undefined") return undefined;

    return await this.loadAppByAppPath(shareEntity.path);
  }

  async loadApp(userId: string, appId: string): Promise<AppModel> {
    const tenantId = environment.tanantId;
    return await this.loadAppByAppPath(`tenant/${tenantId}/user/${userId}/app/${appId}`);
  }

  private async loadAppByAppPath(path: string): Promise<AppModel> {
    const appRef = this.afs.doc<AppEntity>(path);
    const entity = (await appRef.ref.get()).data();

    // Migration
    if (!entity.author) { entity.author = ''; }
    if (!entity.icon) { entity.icon = ''; }
    if (!entity.description) { entity.description = ''; }
    if (!entity.initialPosition) { entity.initialPosition = { gpsOption: { accuracy: "bicycle" } }; }
    if (!entity.initialPosition.gpsOption) { entity.initialPosition.gpsOption = { accuracy: "bicycle" }; }
    if (!entity.mapSourceType) { entity.mapSourceType = 'chiriin'; }
    if (!entity.themeType) { entity.themeType = 'light_default'; }
    if (!entity.shareId) { entity.shareId = ''; }

    return new AppModel(entity);
  }

  async saveApp(userId: string, app: AppModel, refreshManifest: boolean = false, changeShareId: boolean = false, oldShareId: string = '', imageSource: string = ''/*, baseUrl: string = ''*/) {
    const tenantId = environment.tanantId;
    const appRef = this.afs.doc<AppEntity>(`tenant/${tenantId}/user/${userId}/app/${app.id}`);
    const appEntity = Object.assign({} as AppEntity, app);
    await appRef.set(appEntity);
    const time = new Date().getTime();
    if (refreshManifest) {
      // PWA manifest を更新してアップロード
      const manifest = {
        name: app.name,
        short_name: app.name,
        //theme_color: '#1976d2',
        background_color: app.color,
        display: "standalone",
        scope: "./",
        start_url: `https://${app.shareId}.${environment.domain.user}/share_map_page`,//`${baseUrl}/share_map_page;share_id=${app.shareId}`,
        icons: [
          {
            src: "appicon.png",
            sizes: "128x128",
            type: "image/png",
            purpose: "maskable any"
          }
        ]
      };
      const manifestBlob = new File([JSON.stringify(manifest)], "manifest.webmanifest");
      const refManifest = this.storage.ref(`${app.shareId}/manifest.webmanifest`);
      refManifest.put(manifestBlob);
    }
    if (refreshManifest || changeShareId || imageSource != '') {
      // アイコンからiOSのPWA用のスプラッシュスクリーンを生成してアップロード
      let canvas = document.createElement('canvas');
      if (typeof canvas.getContext == 'function') {
        let image = new Image();
        image.crossOrigin = 'Anonymous';
        let src = imageSource!='' ? imageSource : `https://storage.googleapis.com/${environment.firebase.storageBucket}/${oldShareId}/appicon.png?v=${time}`;
        await this.loadImage(image,src);
        let context = canvas.getContext('2d');
        let textColor = this.checkAppColorLuminosity(app.color) == 'dark' ? '#fff' : '#000';
        for (let i = 0; i < environment.splashSize.length; i++) {
          let value = environment.splashSize[i];
          canvas.width = value.width * value.devicePixelRatio;
          canvas.height = value.height * value.devicePixelRatio;
          let imageSize = canvas.width * 0.3;
          let fontSize = canvas.width > 1000 ? 60 : 48;
          context.fillStyle = app.color;
          context.fillRect(0,0,canvas.width,canvas.height);
          context.fill();
          context.fillStyle = textColor;
          context.textAlign = 'center';
          context.textBaseline = 'top';
          context.font = `bold ${fontSize}px "-apple-system, BlinkMacSystemFont"`;
          let appName = [''];
          let line = 0;
          for (let j = 0; j < app.name.length; j++) {
            let char = app.name.charAt(j);
            if (context.measureText(appName[line]+char).width > canvas.width) {
              line++;
              appName[line] = '';
            }
            appName[line] += char;
          }
          let textTop = (canvas.height+imageSize)/2 + 60;
          for (let k = 0; k < appName.length; k++) {
            context.fillText(appName[k],canvas.width/2,textTop);
            textTop += (48+20);
          }
          context.drawImage(image,(canvas.width-imageSize)/2,(canvas.height-imageSize)/2,imageSize,imageSize);
          let imageString = canvas.toDataURL('image/png');
          let splashRef = this.storage.ref(`${app.shareId}/splashscreen-ios-${value.width}-${value.height}-${value.devicePixelRatio}.png`);
          await splashRef.putString(imageString, 'data_url').then();
          context.clearRect(0,0,canvas.width,canvas.height);
        }
        canvas.remove();
      }
    }
    if (changeShareId) {
      // shareIdの変更
      // Firestore shareコレクションの変更
      const shareRef = await this.afs.collection(`share`).doc(app.shareId);
      const oldShareRef = await this.afs.collection(`share`).doc(oldShareId);
      shareRef.set({
        path: `tenant/${tenantId}/user/${userId}/app/${app.id}`
      });
      oldShareRef.delete();
      // Storageフォルダの変更
      const refIcon = this.storage.ref(`${app.shareId}/appicon.png`);
      const oldRefIcon = this.storage.ref(`${oldShareId}/appicon.png`);
      const oldRefManifest = this.storage.ref(`${oldShareId}/manifest.webmanifest`);
      const file = await fetch(`https://storage.googleapis.com/${environment.firebase.storageBucket}/${oldShareId}/appicon.png?v=${time}`)
        .then(response => response.blob())
        .then(blob => new File([blob], "appicon.png"));
      await refIcon.put(file);
      oldRefIcon.delete();
      oldRefManifest.delete();
      for (let i = 0; i < environment.splashSize.length; i++) {
        let value = environment.splashSize[i];
        let oldSplashRef = this.storage.ref(`${oldShareId}/splashscreen-ios-${value.width}-${value.height}-${value.devicePixelRatio}.png`);
        oldSplashRef.delete();
      }
    }
  }

  async deleteApp(userId: string, app: AppModel) {
    const tenantId = environment.tanantId;
    const oldSheetIds = app.sheets.map(function(value){return value.id;});
    let writeCount = 0;
    let batch = this.afs.firestore.batch();
    try {
      for (const oldSheetId of oldSheetIds) {
        const oldRows = await this.afs.collection(`tenant/${tenantId}/user/${userId}/app/${app.id}/sheet/${oldSheetId}/row`).get().toPromise();
        for (const rowDoc of oldRows.docs) {
          batch.delete(rowDoc.ref);
          writeCount++;
          if (writeCount >= 500) {
            await batch.commit();
            writeCount = 0;
            batch = this.afs.firestore.batch();
          }
        }
        batch.delete(this.afs.doc(`tenant/${tenantId}/user/${userId}/app/${app.id}/sheet/${oldSheetId}`).ref);
        writeCount++;
        if (writeCount >= 500) {
          await batch.commit();
          writeCount = 0;
          batch = this.afs.firestore.batch();
        }
      }
      await batch.commit();
      const appRef = this.afs.doc<AppEntity>(`tenant/${tenantId}/user/${userId}/app/${app.id}`);
      await appRef.delete();
  
      const shareAppRef = this.afs.doc<AppEntity>(`share/${app.shareId}`);
      await shareAppRef.delete();

      //const ref = this.storage.ref(`${app.shareId}`);
      //ref.delete();
      const refIcon = this.storage.ref(`${app.shareId}/appicon.png`);
      const refManifest = this.storage.ref(`${app.shareId}/manifest.webmanifest`);
      refIcon.delete();
      refManifest.delete();
      for (let i = 0; i < environment.splashSize.length; i++) {
        let value = environment.splashSize[i];
        let splashRef = this.storage.ref(`${app.shareId}/splashscreen-ios-${value.width}-${value.height}-${value.devicePixelRatio}.png`);
        splashRef.delete();
      }

    } catch (error) {
      console.log(`${this.constructor.name}: deleteApp -> error`, error);
    }
  }

  async loadRows(userId: string, appId: string, sheetId: string): Promise<any[]> {
    const tenantId = environment.tanantId;
    // const rowColl = this.afs.collection<AppEntity>(`tenant/${tenantId}/user/${userId}/app/${appId}/row`);
    const rowColl = this.afs.collection<AppEntity>(`tenant/${tenantId}/user/${userId}/app/${appId}/sheet/${sheetId}/row`);

    const apps = await rowColl.get().toPromise();
    return apps.docs.map(x => x.data());
  }

  checkAppColorLuminosity(colorCode: string): string {
    colorCode.replace(/^#/,'');
    let digit = Math.floor(colorCode.length/3);
    if (digit < 1) return 'dark';
    let rgb = [];
    for (let i = 0; i < 3; i++) rgb.push(parseInt(colorCode.slice(digit*i, digit*(i+1)), 16));
    return (Math.max(rgb[0],rgb[1],rgb[2]) /255) > 0.5 ? 'light' : 'dark';
  }

  loadImage(img: HTMLImageElement, src: string): Promise<void> {
    return new Promise((resolve,reject)=>{
      img.onload = () => resolve();
      img.onerror = (e) => reject(e);
      img.src = src;
    });
  }

  // async importSheetToStore(userId: string, sheet: SheetModel): Promise<string> {
  //   // Sets user data to firestore on login
  //   const tenantId = environment.tanantId;
  //   const appColl = this.afs.collection(`tenant/${tenantId}/user/${userId}/app`);

  //   const cells = await this.gapiService.getCellData(sheet.id);
  //   const columns = cells[0];
  //   const now = moment().format();
  //   const app = {
  //     sheetDocId: sheet.id,
  //     name: sheet.name,
  //     lastSyncTime: now,
  //     lastAccessTime: now,
  //     columns: columns,
  //     color: randomColor()
  //   } as AppEntity;

  //   const appRef = await appColl.add(app);
  //   const rowColl = this.afs.collection(`tenant/${tenantId}/user/${userId}/app/${appRef.id}/row`);

  //   const batch = this.afs.firestore.batch();
  //   try {

  //     for (let index = 1; index < cells.length; index++) {
  //       const rowId = `row${index.toString().padStart(5, '0')}`;
  //       const rowDoc = rowColl.doc(rowId).ref;

  //       const rowData = cells[index].reduce((pre, cur, idx) => {
  //         pre[columns[idx]] = cur;
  //         return pre;
  //       }, {});

  //       batch.set(rowDoc, rowData);
  //     }

  //     await batch.commit();
  //     console.debug(`${this.constructor.name}:importSheetToStore finished. - ${cells.length} rows.`);
  //     return appRef.id;

  //   } catch (error) {
  //     console.warn(`${this.constructor.name}:importSheetToStore failed`, error);
  //   }
  // }
}

export type RefreshFlags = {
  refreshMap: boolean;
  refreshManifest: boolean;
  changeShareId: boolean;
  oldShareId: string;
  imageSource: string;
  searchItemChange: {
    type: 'add' | 'change' | 'delete';
    index: number;
  };
  callback: ()=>void;
}