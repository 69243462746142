<div class="container-fluid">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px', fullScreenBackdrop: true }"></ngx-loading>

  <div class="row">
    <div class="col-2 d-flex flex-column align-items-start justify-content-start bg-primary">
      <div class="mt-4">
        <div class="avatar primary">
          <img [src]="user.photoURL"
            alt="avatar mx-auto white" class="rounded-circle img-fluid"
            style="width: 50px; height: 50px;"/>
        </div>

        <div>{{ user.displayName }} さん</div>
      </div>
      <div class="my-2 w-100">
        <button class="btn btn-secondary btn-block" (click)="logout()">ログアウト</button>
      </div>
    </div>
    <div class="col-10 py-4" style="height: 100vh; overflow-y: auto;">
      <div class="row">
        <div class="col-12">
          <h3>作成したアプリ</h3>
          <hr class="hr-dark">
        </div>

        <div class="col-12 d-flex flex-row flex-wrap">
          <app-sheet-search-card class="mt-2 mr-2" (click)="onSheetSearchClick()"></app-sheet-search-card>
          <app-app-card *ngFor="let item of apps" [item]="item" (click)="onAppClick(item)" class="mt-2 mr-2"></app-app-card>
        </div>

      </div>

      <!--<div class="row mt-4">
        <div class="col-12">
          <div class="d-flex align-items-end justify-content-between">
            <h3>スプレッドシートからアプリを作成</h3>
            <div>

              <form class="input-group input-group-sm">
                <input class="form-control flex-fill" type="text"
                  [ngModelOptions] = "{standalone: true}"
                  placeholder="キーワードで検索" [(ngModel)]="searchWord">

                <div class="input-group-append" id="button-addon4">
                  <button class="btn btn-outline-secondary" type="button" (click)="onSearchSheet()">
                    <svg width="0.8em" height="0.8em" viewBox="0 0 16 16" class="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"/>
                      <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                    </svg>
                  </button>
                </div>
              </form>

            </div>
          </div>
          <hr class="hr-dark">
        </div>

        <div class="col-12 d-flex flex-row flex-wrap">
          <app-sheet-card *ngFor="let item of sheets" [item]="item" (click)="onSheetClick(item)" class="mt-2 mr-2"></app-sheet-card>
        </div>

      </div>-->

    </div>
  </div>
</div>
