<!-- <div class="jumbotron card card-image bg-primary" style="height: 300px;">
  <div class="text-light text-center py-2 px-2">
    <div>
      <h2 class="card-title h1-responsive pt-2 mb-5 font-bold"><strong>SMAP for Spreadsheet</strong></h2>
      <p class="mx-5 mb-2">
        SMAP for Spreadsheet は、Google SpreadSheets のデータを誰でも簡単に地図アプリにできるサービスです。
        Google アカウントでログインして始めてください。
      </p>
    </div>
  </div>
</div> -->

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px', fullScreenBackdrop: true }"></ngx-loading>

<div class="d-flex flex-column text-light bg-primary" style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;">

  <div class="p-2" style="font-size: 1.5rem; font-weight: bold;"><img style="width: 162px; height: 60px; object-fit: contain;" src="MapQuestLogo-with-slogan-white.svg"></div>

  <div class="flex-fill d-flex flex-column justify-content-center container-fluid">
    <div class="row">
      <div class="col-12 mb-4 pb-4 text-center" style="padding-bottom: 0px !important;">
        <h1 style="font-size: 5rem; line-height: .9;">
          <strong>どすごいマップ<br/><span style="font-size: 3rem;">for</span><br/><span  style="font-size: 3rem;">Spreadsheet</span></strong>
        </h1>
      </div>

      <div class="col-12 col-lg-4">
      </div>

      <div class="col-12 col-lg-4 my-4 pb-4">
        <button class="btn btn-outline-primary btn-lg btn-block"
          style="background-color: white !important;"
          (click)="start()">Google アカウントで始める</button>
      </div>

      <div class="col-12 col-lg-4">
      </div>

    </div>
  </div>

  <!-- <div class="flex-fill d-flex flex-column justify-content-center">
    <h1 class="card-title h1-responsive pt-2 mb-5 font-bold">
      <strong>SMAP<br/>for<br/>Spreadsheet</strong>
    </h1>

    <div class="row">
      <div class="col-12">

      </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center">
      <button class="btn btn-outline-primary btn-lg btn-block m-2"
        style="background-color: white !important;"
        (click)="start()">Google アカウントで始める</button>
    </div>
  </div> -->

</div>
