<ng-container *ngIf="label != null && label != ''">
  <label class="field-label">{{label}}</label>
</ng-container>
<div
  ngbDropdown class="w-100 d-inline-block">
  <button
    class="btn btn-block"
    id="dropdownBasic1"
    ngbDropdownToggle
    [style.background-color]="ngModel"
  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
    <div ngbDropdownItem *ngFor="let opt of presetColors">
      <button class="btn btn-sm btn-block"
        [style.background-color]="opt"
        (click)="onClick(opt)"
      >&nbsp;</button>
    </div>
  </div>
</div>
