<div class="flex-fill">

  <div class="container-fluid">
    <div class="row my-1">

      <div class="col-12 mt-1">
        <img style="width: 42px; height: 42px;" [src]="appImage">
      </div>

      <div class="col-12">
        <small class="text-light" style="cursor: pointer;"><a (click)="back()">
          <i class="fas fa-chevron-left"></i>
          アプリ一覧
        </a></small>
      </div>

      <div class="col-12 mt-1 text-light">
        <h4>{{ app.name }}</h4>
        <hr class="hr-light"/>
      </div>

      <div class="col-12 text-light">
        <app-sheet-select
          [sheets]="sheets"
          [value]="selectedSheetId"
          (valueChange)="onSelectedSheetIdChange($event)"
          [checked]="selectedSheetEnabled"
          (checkedChange)="onSelectedSheetEnabledChange($event)"
        ></app-sheet-select>
      </div>

      <div class="col-12 d-flex flex-row align-items-center text-light">
        <app-text-field
          label="タブ名"
          [(ngModel)]="sheetName"
          (blur)="onSheetNameDecided($event)"
          (keyup)="onSheetNameDecided($event)"
          (focus)="onSheetNameFocus()"
          (ngModelChange)="onSheetNameEdit()"
        ></app-text-field>

        <app-icon-field
          class="ml-1"
          label="&nbsp;"
          [(ngModel)]="sheet.icon"
          (ngModelChange)="onDirty(false)"
        ></app-icon-field>

      </div>

      <div class="col-12">
        <app-initial-position-setting
          [(value)]="sheet.initialPosition"
          (valueChange)="onDirty(false)"
          (requestMapPosition)="onRequestMapPosition($event)"
          (initGps)="onInitGps()"
          (moveToAllMarker)="onMoveToAllMarker()"
          [onModal]="false"
          [reservedSaveCount]="reservedSaveCount"
        ></app-initial-position-setting>
      </div>

      <div class="col-12">
        <app-map-source-select
          [(value)]="sheet.mapSourceType"
          (valueChange)="onDirty(true)"
        ></app-map-source-select>
      </div>

    </div>
  </div>

</div>

<div>
  <div class="container-fluid">
    <div class="row my-1">

      <div class="col-12 mt-1">
        <button class="btn btn-secondary btn-block" (click)="onGotoGoogleDoc()">
          <i class="fas fa-table"></i>
          シートを開く
        </button>
      </div>

      <div class="col-12 mt-1">
        <button class="btn btn-secondary btn-block" (click)="onReload()">
          <i class="fas fa-sync-alt"></i>
          シート再読み込み
        </button>
      </div>

      <div class="col-12 mt-1">
        <button class="btn  btn-secondary btn-block" (click)="onShowAppSettingModal()">
          <i class="fas fa-cog"></i>
          アプリ設定
        </button>
      </div>

      <!--<div class="col-12 mt-1">
        <button class="btn  btn-secondary btn-block" (click)="onShowAppShareModal()">
          <i class="fas fa-share-alt"></i>
          アプリ公開
        </button>
      </div>-->

      <div class="col-12 mt-2">

        <div class="avatar">
          <img [src]="user.photoURL"
            alt="avatar mx-auto white" class="rounded-circle img-fluid"
            style="width: 50px; height: 50px;"/>
        </div>

        <div>{{ user.displayName }} さん</div>

      </div>
    </div>
  </div>
</div>
