<!-- <app-mddb-select
  [outline]="true"
  [options]="options"
  [ngModel]="value"
  (ngModelChange)="onValueChange($event)"
  [placeholder]="placeholder"
  [label]="title">
</app-mddb-select> -->


<div class="d-flex align-items-center">
  <div class="text-right mr-2" style="flex-basis: 30px;"><small>{{no}}.</small></div>

  <app-mddb-select class="mr-2"
    [options]="options"
    [ngModel]="value.column"
    (ngModelChange)="onColumnChange($event)"
    [placeholder]="placeholder"
  ></app-mddb-select>

  <app-mddb-select class="mr-2"
    [options]="valueTypes"
    [ngModel]="value.valueType"
    (ngModelChange)="onValueTypeChange($event)"
    [placeholder]="'種類'"
  ></app-mddb-select>

  <button type="button" class="close" style="flex-basis: 30px;" aria-label="Close" (click)="delete.emit()">
    <span aria-hidden="true">×</span>
  </button>

</div>
