import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { InitialPositionDef, InitialPotisionType, InitialPositionLatLngDef } from 'src/app/usecase/sheet-usecase.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-initial-position-setting',
  templateUrl: './initial-position-setting.component.html',
  styleUrls: ['./initial-position-setting.component.scss']
})
export class InitialPositionSettingComponent {
  @Input() value: InitialPositionDef;
  @Input() onModal: boolean;
  @Input() reservedSaveCount: {reservedSaveTime: number};
  @Output() readonly valueChange = new EventEmitter<InitialPositionDef>();
  @Output() readonly requestMapPosition = new EventEmitter<(InitialPositionLatLngDef)=>void>();
  @Output() readonly initGps = new EventEmitter<void>();
  @Output() readonly moveToAllMarker = new EventEmitter<void>();

  get initialPositionType(): InitialPotisionType {
    if (this.value == null) {
      return "boundingBox";
    }
    return this.value.type;
  }
  set initialPositionType(value: InitialPotisionType) { this.reCreateValue({ type: value }); }

  get zoomLevel(): number {
    if (this.value == null) {
      return 15;
    }
    return this.value.zoom;
  }
  set zoomLevel(value: number) { this.reCreateValue({ zoom: value }); }

  get latLonZoomDef(): InitialPositionLatLngDef {
    if (this.value == null) {
      return {} as InitialPositionLatLngDef;
    }
    return this.value.latLonZoom;
  }
  set latLonZoomDef(value: InitialPositionLatLngDef) { this.reCreateValue({ latLonZoom: value }); }

  constructor() { }

  reCreateValue(update: Partial<InitialPositionDef>) {
    const cloned = Object.assign({}, this.value);

    if (update.type != null) {
      cloned.type = update.type;

      if (cloned.type == "gps") {
        if (cloned.gpsOption == null) cloned.gpsOption = {};
        this.initGps.emit();
      } else if (cloned.type == "boundingBox") this.moveToAllMarker.emit();
    }

    if (update.gpsOption != null) {
      cloned.gpsOption = update.gpsOption;
    }

    if (update.latLonZoom != null) {
      cloned.latLonZoom = update.latLonZoom;
    }

    if (typeof update.zoom !== 'undefined') {
      cloned.zoom = update.zoom;
    }

    this.value = cloned;
    this.valueChange.emit(this.value);

    if (update.type != null && cloned.type == "latLonZoom") this.onApplyMapPositionRequest();
  }

  onApplyMapPositionRequest() {
    const f = (x:InitialPositionLatLngDef)=>{
      this.latLonZoomDef = { center: x.center.slice(0) } as InitialPositionLatLngDef;
      this.zoomLevel = x.zoom;
    };
    this.requestMapPosition.emit(f);
  }
}
