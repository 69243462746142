<div class="card d-flex flex-column" [style.background-color]="item.color" style="width: 200px; height: 150px;">

  <div class="flex-grow-1 my-2 mx-3" [style.color]="foregroundColor">
    <!-- <i class="material-icons" style="font-size: 40px;">{{item.icon}}</i> -->
    <img style="width: 40px; height: 40px;" [src]="appImage">
    <h6>{{ item.name.substring(0, 30) }}</h6>
  </div>

  <div class="bg-white rounded-bottom">
    <hr class="mt-0 mb-0"/>
    <div class="d-flex flex-column my-2 mx-3">
      <small class="text-secondary align-self-end">{{ item.lastAccessTimeFormated }} に使用</small>
    </div>
  </div>

  <!-- <div class="d-flex flex-column justify-content-between">

    <h5>赤ちゃんの駅べびほっ</h5>
    <small class="text-secondary text-right">2020/01/31</small>
  </div> -->

</div>
