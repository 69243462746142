import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthUsecase } from 'src/app/usecase/auth-usecase.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
  loading = false;

  constructor(
    private router: Router,
    public auth: AuthUsecase,
  ) { }

  async ngOnInit() {
    if (location.href.includes(environment.domain.user)) {
      this.router.navigate(['/share_map_page']);
    }
  }

  async start() {
    this.loading = true;
    try {
      if (await this.auth.signInByGoogleApi()) {
        this.router.navigate(['/app_list']);
      }
    } catch (error) {
      console.warn(`${this.constructor.name}:start failed.`, error);
    } finally {
      this.loading = false;
    }
  }

}
