import { Injectable } from '@angular/core';
import { ShareModalComponent } from './share-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class ShareModalService {

  constructor(private modalService: NgbModal) {
  }

  async show(shareId: string): Promise<void> {
    console.debug(`${this.constructor.name}:show`, shareId);

    const modalRef = this.modalService.open(ShareModalComponent, { ariaLabelledBy: 'modal-basic-title', centered: true});
    const conpoment = modalRef.componentInstance as ShareModalComponent;
    conpoment.shareId = shareId;

    await modalRef.result;
  }
}
