import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent {
  shareId: string;

  elementType = 'url';
  userUrl = 'url';

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.userUrl = `https://${this.shareId}.${environment.domain.user}/share_map_page`;
  }

  onCloseClick() {
    this.activeModal.close();
  }

  onCopy(isUser: boolean){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.userUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.toastr.success('URLをコピーしました。', null, {closeButton: true});
  }
}
