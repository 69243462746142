import { Injectable } from '@angular/core';
//import { first } from 'rxjs/operators';
import { SheetSearchModalComponent } from './sheet-search-modal.component';
import { SpreadsheetModel, SheetSearchParams } from 'src/app/usecase/sheet-usecase.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class SheetSearchModalService {

  modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {
  }

  async show(sheets: SpreadsheetModel[], searchParams: SheetSearchParams, toastr: ToastrService, onSort: (boolean)=>void, onSearchSheet: ()=>void, createApp: (SpreadsheetModel)=>Promise<boolean>, clearRemainingError: ()=>void): Promise<void> {
    console.debug(`${this.constructor.name}:show`, sheets);

    // this.modalService.show(SheetSearchModalComponent, modalOptions);
    // return this.modalService.closed.pipe(first()).toPromise();

    const modalRef = this.modalService.open(SheetSearchModalComponent, { ariaLabelledBy: 'modal-basic-title', size: 'xl', centered: true});
    const component = modalRef.componentInstance as SheetSearchModalComponent;
    component.sheets = sheets;
    component.searchParams = searchParams;
    component.toastr = toastr;
    component.onSort = onSort;
    component.onSearchSheet = onSearchSheet;
    component.createApp = createApp;
    component.clearRemainingError = clearRemainingError;

    this.modalRef = modalRef;

  }

  async updateSheets(sheets: SpreadsheetModel[]): Promise<void> {
    if(this.modalRef) {
      const component = this.modalRef.componentInstance as SheetSearchModalComponent;
      component.sheets = sheets;
      component.selectedSheet = null;
    }
  }
}
