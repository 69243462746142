import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { StringUtils } from 'src/app/util/string-utils';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// see https://traveler0401.com/angular-error-accessor
const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextFieldComponent),
  multi: true
};

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class TextFieldComponent implements OnInit, ControlValueAccessor {
  @Input() ngModel: string;
  @Output() readonly ngModelChange = new EventEmitter<string>();
  @Input()  placeholder = '';
  @Input() label = '';
  @Input() type = 'text';
  @Output() readonly blur = new EventEmitter<Event>();
  @Output() readonly focus = new EventEmitter<Event>();
  @Input() contentError = false;
  @Input() contentWarning = false;

  id = `text-field-${StringUtils.randomString(4)}`;

  constructor() { }
  writeValue(obj: any): void {
    // throw new Error("Method not implemented.");
  }
  registerOnChange(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }

  ngOnInit(): void {
  }

  onValueChange(event) {
    this.ngModelChange.emit(event);
  }

  onBlur(event: Event) {
    this.blur.emit(event);
  }
  onFocus(event: Event) {
    this.focus.emit(event);
  }
}
