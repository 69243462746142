import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { SearchItemDef } from 'src/app/usecase/sheet-usecase.service';

@Component({
  selector: 'app-search-column-select',
  templateUrl: './search-column-select.component.html',
  styleUrls: ['./search-column-select.component.scss']
})
export class SearchColumnSelectComponent implements OnInit {
  @Input() no = '';
  @Input() placeholder = '';
  @Input() visibleDeleteButton = true;

  @Output() readonly delete = new EventEmitter<void>();

  @Input('columns')
  set columnsSetter(value: string[]) {
    this.options = value.map(x => {
      return { value: x, label: x };
    });

    const org = this.value;
    this.value = {} as SearchItemDef;
    this.ref.detectChanges();
    this.value = org;
    this.ref.detectChanges();
  }
  @Input() value: SearchItemDef;
  @Output() readonly valueChange = new EventEmitter<SearchItemDef>();

  options: { value: string, label: string }[] = [];

  valueTypes: { value: string, label: string }[] = [
    { value: 'text', label: 'テキスト' },
    { value: 'date', label: '日付' },
    { value: 'list', label: 'リスト' },
  ];

  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit() {
  }

  onColumnChange(event) {
    const newValue = this.value; // Object.assign({}, this.value);
    newValue.column = event;
    this.value = newValue;
    this.valueChange.emit(this.value);
  }

  onValueTypeChange(event) {
    const newValue = this.value; // Object.assign({}, this.value);
    newValue.valueType = event;
    this.value = newValue;
    this.valueChange.emit(this.value);
  }

}
