import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxLoadingModule } from 'ngx-loading';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { ColorPickerModule } from 'ngx-color-picker';
import { IconPickerModule } from 'ngx-icon-picker';
import { ToastrModule } from 'ngx-toastr';
import { NgxQRCodeModule } from 'ngx-qrcode2';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { StartComponent } from './ui/page/start/start.component';
import { RedirectComponent } from './ui/page/redirect/redirect.component';
import { AppListComponent } from './ui/page/app-list/app-list.component';
import { SheetCardComponent } from './ui/app-component/sheet-card/sheet-card.component';
import { AppCardComponent } from './ui/app-component/app-card/app-card.component';
import { SheetSearchCardComponent } from './ui/app-component/sheet-search-card/sheet-search-card.component';
import { SheetSearchModalComponent } from './ui/modal/sheet-search-modal/sheet-search-modal.component';
import { ShhetSearchOrderSelectComponent } from './ui/app-component/sheet-search-order-select/sheet-search-order-select.component';
import { MapPageComponent } from './ui/page/map-page/map-page.component';
import { AppSettingPanelComponent } from './ui/app-component/app-setting-panel/app-setting-panel.component';
import { SheetSettingPanelComponent } from './ui/app-component/sheet-setting-panel/sheet-setting-panel.component';
import { MapViewComponent } from './ui/app-component/map-view/map-view.component';
import { RowSummaryPanelComponent } from './ui/app-component/row-summary-panel/row-summary-panel.component';
import { BottomPanelComponent } from './ui/app-component/bottom-panel/bottom-panel.component';
import { SearchPanelComponent } from './ui/app-component/search-panel/search-panel.component';
import { MapSourceSelectComponent } from './ui/app-component/map-source-select/map-source-select.component';
import { InitialPositionSettingComponent } from './ui/app-component/initial-position-setting/initial-position-setting.component';
import { InitialPositionTypeSelectComponent } from './ui/app-component/initial-position-type-select/initial-position-type-select.component';
import { FavComponent } from './ui/app-component/fav/fav.component';
import { SheetSelectComponent } from './ui/app-component/sheet-select/sheet-select.component';
import { ColumnSelectComponent } from './ui/app-component/column-select/column-select.component';
import { InitialPositionLatLngSettingComponent } from './ui/app-component/initial-position-lat-lng-setting/initial-position-lat-lng-setting.component';
import { MarkerColumnSelectComponent } from './ui/app-component/marker-column-select/marker-column-select.component';
import { InfoWindowColumnSelectComponent } from './ui/app-component/info-window-column-select/info-window-column-select.component';
import { SearchColumnSelectComponent } from './ui/app-component/search-column-select/search-column-select.component';
import { ZoomLevelSelectComponent } from './ui/app-component/zoom-level-select/zoom-level-select.component';
import { ConfirmModalContent } from './ui/modal/confirm-modal/confirm-modal-content';
import { InfoWindowModalComponent } from './ui/modal/info-window-modal/info-window-modal.component';
import { AppSettingModalComponent } from './ui/modal/app-setting-modal/app-setting-modal.component';
import { ThemeTypeSelectComponent } from './ui/app-component/theme-type-select/theme-type-select.component';
import { MapTypeModalComponent } from './ui/modal/map-type-modal/map-type-modal.component';
import { MddbSelectComponent } from './ui/app-component/mddb-select/mddb-select.component';
import { MddbSelectObjectComponent } from './ui/app-component/mddb-select-object/mddb-select-object.component';
import { TextFieldComponent } from './ui/app-component/text-field/text-field.component';
import { CheckBoxComponent } from './ui/app-component/check-box/check-box.component';
import { IconFieldComponent } from './ui/app-component/icon-field/icon-field.component';
import { ColorFieldComponent } from './ui/app-component/color-field/color-field.component';
import { PresetColorFieldComponent } from './ui/app-component/preset-color-field/preset-color-field.component';
import { ImageUploadComponent } from './ui/app-component/image-upload/image-upload.component';
import { ImageDropPanelComponent } from './ui/app-component/image-drop-panel/image-drop-panel.component';
import { ShareModalComponent } from './ui/modal/share-modal/share-modal.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NoAppComponent } from './ui/page/no-app/no-app.component';
@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    RedirectComponent,
    AppListComponent,
    SheetCardComponent,
    AppCardComponent,
    SheetSearchCardComponent,
    SheetSearchModalComponent,
    ShhetSearchOrderSelectComponent,
    MapPageComponent,
    AppSettingPanelComponent,
    SheetSettingPanelComponent,
    MapViewComponent,
    RowSummaryPanelComponent,
    BottomPanelComponent,
    SearchPanelComponent,
    MapSourceSelectComponent,
    InitialPositionSettingComponent,
    InitialPositionTypeSelectComponent,
    FavComponent,
    SheetSelectComponent,
    ColumnSelectComponent,
    InitialPositionLatLngSettingComponent,
    MarkerColumnSelectComponent,
    InfoWindowColumnSelectComponent,
    SearchColumnSelectComponent,
    ZoomLevelSelectComponent,
    ConfirmModalContent,
    InfoWindowModalComponent,
    AppSettingModalComponent,
    ThemeTypeSelectComponent,
    MapTypeModalComponent,
    MddbSelectComponent,
    MddbSelectObjectComponent,
    TextFieldComponent,
    CheckBoxComponent,
    IconFieldComponent,
    ColorFieldComponent,
    PresetColorFieldComponent,
    ImageUploadComponent,
    ImageDropPanelComponent,
    ShareModalComponent,
    NoAppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxLoadingModule,
    LeafletModule,
    LeafletDrawModule,
    ColorPickerModule,
    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app needed for everything
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    IconPickerModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    NgxQRCodeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    { provide: BUCKET, useValue: environment.firebase.storageBucket }
  ],
  entryComponents: [
    ConfirmModalContent,
    InfoWindowModalComponent,
    AppSettingModalComponent,
    MapTypeModalComponent,
    ShareModalComponent,
    SheetSearchModalComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
