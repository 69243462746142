<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title w-100" id="myModalLabel">アプリ設定</h4>

    <button type="button" class="close" aria-label="Close" (click)="onCloseClick()">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>
  <div class="modal-body container-fluid">
    <div class="row">
      <div class="col-8">
        <div class="row">

          <div class="col-12">
            <app-text-field
              label="アプリケーション名"
              [(ngModel)]="name"
              (ngModelChange)="onTextEdit('name')"
              (blur)="onTextDecided($event)"
              (keyup)="onTextDecided($event)"
              (focus)="onTextFocus('name')"
            ></app-text-field>
          </div>

          <!-- <div class="col-1">
            <app-icon-field
              label="アイコン"
              [(ngModel)]="app.icon"
              (ngModelChange)="onDirty(false)"
            ></app-icon-field>
          </div> -->

          <div class="col-6">
            <app-text-field
              label="作成者"
              [(ngModel)]="author"
              (ngModelChange)="onTextEdit('author')"
              (blur)="onTextDecided($event)"
              (keyup)="onTextDecided($event)"
              (focus)="onTextFocus('author')"
            ></app-text-field>
          </div>

          <div class="col-2">
            <app-color-field
              label="色"
              [(ngModel)]="app.color"
              (ngModelChange)="onAppColorChanged()"
            ></app-color-field>
          </div>

          <div class="col-4">
            <app-theme-type-select
            [(value)]="app.themeType"
            (valueChange)="onDirty(false)"
            ></app-theme-type-select>
          </div>

        </div>

      </div>
      <div class="col-4">
        <app-image-upload
          [shareId]="app.shareId"
          (valueChange)="onImageChanged($event)"
          (imageUploadStart)="imageUploadStart()"
          (imageUploadFinish)="imageUploadFinish()"
        ></app-image-upload>
      </div>


      <div class="col-12">
        <app-text-field
          label="説明"
          [(ngModel)]="description"
          (ngModelChange)="onTextEdit('description')"
          (blur)="onTextDecided($event)"
          (keyup)="onTextDecided($event)"
          (focus)="onTextFocus('description')"
        ></app-text-field>
      </div>

      <div class="col-12">
        <app-initial-position-setting
          [(value)]="sheet.initialPosition"
          (valueChange)="onDirty(true)"
          (requestMapPosition)="onRequestMapPosition($event)"
          (initGps)="initGps()"
          (moveToAllMarker)="moveToAllMarker()"
          [onModal]="true"
          [reservedSaveCount]="reservedSaveCount"
        ></app-initial-position-setting>
      </div>


      <div class="col-12 mt-2">
        <app-map-source-select
          [(value)]="sheet.mapSourceType"
          (valueChange)="onDirty(true)"
        ></app-map-source-select>
      </div>


      <div class="col-12">
        <div class="row">
          <div class="col-3" style="padding-right: 0;">
            <app-text-field
              label="公開URL"
              [(ngModel)]="shareId"
              (ngModelChange)="onShareIdChanged()"
              (blur)="onShareIdPublished($event)"
              (keyup)="onShareIdPublished($event)"
            ></app-text-field>
            <div class="field-label" style="color: #f00" *ngIf="!shareIdPublishEnable">
              {{shareIdErrorMessage}}
            </div>
          </div>
          <div class="col-1" style="padding-left: 0;margin-right: 15px;">
            <div class="field-label" style="margin-top: 2.5rem;">{{domain}}</div>
          </div>
          <div class="col-2">
            <button type="button" class="col-12 btn btn-secondary mt-4" [class.btn-disabled]="!shareIdPublishEnable" (click)="shareIdPublishEnable&&onShareModalShow()">公開</button>
          </div>
        </div>
      </div>


    </div>

    <div class="row">
      <div class="col-12 mt-4">
        <button class="btn btn-outline-secondary" (click)="onDeleteAppOuter()">アプリケーション削除</button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" ngbAutofocus class="col-2 btn btn-primary" aria-label="Close" (click)="onCloseClick()">閉じる</button>
  </div>
</div>
